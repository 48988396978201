import { Address } from 'wagmi';

import { ServerProfile } from './profile';
import { Nullable } from '../common';
import { ImageData } from '../common/imageData';

export type ServerNft = {
  primary_id: NftId;
  id: NftId;
  collection: { address: Address };
  uri: string;
  name?: string;
  description?: string;
  image?: string;
  external_url?: string;
  price?: number;
  fee?: number;
  creator?: ServerProfile;
  owner?: ServerProfile;
  orders?: (NftOrder & { signature: string })[];
};

export type Nft = ServerNft;

export type NewNft = Nullable<
  Pick<Nft, 'name' | 'description'> & {
    image: ImageData;
    collection?: Address;
    price?: number | '';
    royalty?: number | '';
  }
>;

export type NftOrder = {
  maker: Address;
  taker: Address;
  collection: Address;
  currency: Address;
  price: string; // wei
  tokenId: number;
  start: number;
  end: number;
  params: string;
};

export type NftId = number;

export enum Unit {
  ETH = 'ETH',
}

export type HotCollectionItem = {
  id: string;
  avatar: string;
  name: string;
  price: number;
  floorPrice: number;
  priceChange: number;
  currency: Unit;
};

export type HotCollection = HotCollectionItem[];

export type UserInfo = {
  address: Address;
  username?: string;
  avatar?: string;
  email?: string;
  bio?: string;
};
