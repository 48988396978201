import * as R from 'ramda';
import { memo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntersection, useMount } from 'react-use';

import { getMockFavoritedNft } from 'services/api/fake';
import { awaitTimeout } from 'shared/helpers';
import type { Nft } from 'shared/types';

import { Goods } from './Goods';
import S from './OnSale.module.scss';

const Component = () => {
  const [goodsList, setGoodsList] = useState<Nft[] | null>(null);

  const rootRef = useRef(null);
  const intersection = useIntersection(rootRef, {});
  const isSectionActive = Boolean(intersection?.isIntersecting);

  useEffect(
    function loadGoodData() {
      if (!isSectionActive || goodsList !== null) return;

      (async function IIFE() {
        await awaitTimeout();
        const requestsList = R.range(1, 13).map(getMockFavoritedNft);
        const list = await Promise.all(requestsList);
        setGoodsList(list);
      })();
    },
    [isSectionActive, goodsList],
  );

  return (
    <section className={S.root} ref={rootRef}>
      <div className={S.header}>
        <h2 className={S.title}>On Sale</h2>
        <Link className={S.link} to="/create/nft">
          View all
        </Link>
      </div>
      <Goods goodsList={goodsList} />
    </section>
  );
};

export const OnSale = memo(Component);
OnSale.displayName = 'OnSale';
