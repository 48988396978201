import { Image } from 'semantic-ui-react';

import S from './AssetImage.module.scss';
import { Likes } from '../Likes';

type Props = {
  url: string;
  likes?: number;
};

export const AssetImage: React.FC<Props> = ({ url, likes }) => {
  return (
    <div className={S.root}>
      <Image className={S.image} src={url} />
      {!!likes && <Likes amount={likes} />}
    </div>
  );
};
