import { Contract } from 'alchemy-sdk';
import { Address } from 'wagmi';

import { stores, wagmiClient } from 'core';

import { customErrors, handleError } from '../../helpers';
import { Response } from '../../types';
import { AbiItem } from '../types';

async function switchToDefaultNetwork() {
  // TODO: remove this after subscribing to a network switching event
  const { connector } = wagmiClient;
  const supportedChainId = connector?.chains[0]?.id;
  const currentChainId = await connector?.getChainId();
  if (supportedChainId && supportedChainId !== currentChainId) {
    await connector.switchChain?.(supportedChainId);
  }
}

type SendTransactionArgs = {
  address: Address;
  abi: AbiItem[];
  functionName: string;
  args: unknown[];
};

export async function sendTransaction({
  address,
  abi,
  functionName,
  args,
}: SendTransactionArgs): Promise<Response<string>> {
  try {
    stores.globalLoaderStore.activate();

    await switchToDefaultNetwork();

    const signer = await wagmiClient.connector?.getSigner();
    const contract = new Contract(address, abi, signer);

    if (!window.ethereum) {
      throw new Error(customErrors.walletNotFound);
    }

    const txParams = {
      from: window.ethereum.selectedAddress,
      to: address,
      data: contract.interface.encodeFunctionData(functionName, args),
    };

    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [txParams],
    });

    const { status } = await wagmiClient.provider.waitForTransaction(txHash);

    if (status !== 1) {
      throw new Error(customErrors.transactionError);
    }

    return { data: txHash, error: null };
  } catch (error) {
    return handleError(error as Error);
  } finally {
    stores.globalLoaderStore.deactivate();
  }
}
