import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { Avatar, Button, FieldController } from 'shared/components';
import { toFileUrl } from 'shared/helpers';
import { Profile } from 'shared/types';

import S from './EditProfileForm.module.scss';
import { fields } from './helpers/fields';
import { useStore } from '../../store/useStore';

const EditProfileForm = observer(() => {
  const { profile, updateProfile } = useStore();
  const {
    control,
    formState: { errors, isDirty, defaultValues },
    watch,
    reset,
    handleSubmit,
  } = useForm<Profile>({ mode: 'onChange' });
  const [isChangedForm, setIsChangedForm] = useState(isDirty);

  const avatar = watch('avatar');
  const avatarSrc = toFileUrl(avatar);

  const uploadField = fields.file;

  const navigate = useNavigate();

  const onSubmit = async (data: Profile) => {
    await updateProfile(data, () => navigate('/profile'));
  };

  useEffect(() => {
    if (profile !== null) {
      reset(profile);
    }
  }, [profile, reset]);

  useEffect(() => {
    const isChangedAvatar = typeof avatar === 'object' && avatar !== null && avatar.file !== defaultValues?.avatar;
    setIsChangedForm(isDirty || isChangedAvatar);
  }, [avatar, defaultValues?.avatar, isDirty]);

  return (
    <Form className={S.root} noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={S.uploader}>
        {avatarSrc && <Avatar className={S.avatar} src={avatarSrc} title={profile?.username} size="lg" />}
        <FieldController
          key={uploadField.name}
          className={S.uploadField}
          control={control}
          field={uploadField}
          error={errors[uploadField.name]}
        />
      </div>
      <div className={S.container}>
        <Form.Group className={S.group}>
          {fields.basics.map((field) => (
            <FieldController key={field.name} control={control} field={field} error={errors[field.name]} />
          ))}
        </Form.Group>
        <Form.Group className={S.group}>
          <h3 className={S.groupTitle}>Social Links</h3>
          <p className={S.groupDescription}>Add your existing social links to build a stronger reputation</p>
          {fields.additionals.map((field) => (
            <FieldController key={field.name} control={control} field={field} error={errors[field.name]} />
          ))}
        </Form.Group>
        <Button fill="solid" disabled={!isChangedForm}>
          Save Settings
        </Button>
      </div>
    </Form>
  );
});

export { EditProfileForm };
