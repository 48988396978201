import cx from 'classnames';
import { memo } from 'react';

import S from './Jetton.module.scss';

type Props = {
  image: string;
  className?: string;
};

const Component: React.FC<Props> = ({ image, className }) => (
  <div className={cx(S.root, className)}>
    <img className={S.image} src={image} alt="" />
  </div>
);

export const Jetton = memo(Component);
Jetton.displayName = 'Jetton';
