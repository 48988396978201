import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Statistic } from 'semantic-ui-react';

import { Avatar, Button, CollapsedText, CopyIcon } from 'shared/components';
import { toFileUrl, truncate } from 'shared/helpers';
import { Option, ProfileMetadata, ServerProfile } from 'shared/types';

import S from './ProfileInfo.module.scss';

type Props = {
  profile: ServerProfile | null;
  metadata?: ProfileMetadata | null;
  editable?: boolean;
};

const ProfileInfo: React.FC<Props> = observer(({ profile, metadata, editable = true }) => {
  const navigate = useNavigate();

  const handleEditButtonClick = () => {
    navigate('/profile/edit');
  };

  const { avatar, bio, username, address } = profile ?? {};
  const avatarSrc = toFileUrl(avatar);
  const meta: Option[] | null | undefined = metadata && [
    { value: metadata.likes, text: 'Likes' },
    { value: metadata.liked, text: 'Liked' },
    { value: metadata.createdItems, text: 'Items' },
  ];

  return (
    <section className={S.root}>
      <Avatar className={S.avatar} src={avatarSrc} title={username} />
      <div className={S.header}>
        {username && <CollapsedText className={S.username} text={username} rowLimit={2} />}
        <div className={S.headerInfo}>
          {address && (
            <div className={S.wallet}>
              <span>{truncate(address)}</span>
              <CopyIcon text={address} />
            </div>
          )}
          {editable && (
            <Button className={S.editButton} fill="solid" theme="light" onClick={handleEditButtonClick}>
              Edit
            </Button>
          )}
        </div>
      </div>
      {bio && <CollapsedText className={S.bio} text={bio} rowLimit={3} />}
      {meta && (
        <div className={S.meta}>
          {meta.map((item) => (
            <Statistic
              key={item.text}
              className={S.metaItem}
              value={item.value.toLocaleString('en')}
              label={item.text}
              size="mini"
            />
          ))}
        </div>
      )}
    </section>
  );
});

export { ProfileInfo };
