import { Axios, AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { stores } from 'core/configureStores';
import { config } from 'shared/helpers';

import { handleError } from './helpers/handleError';

const axiosConfig: AxiosRequestConfig = {
  baseURL: config.backendUrl,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrftoken'),
    Authorization: `Signature ${Cookies.get('siwe')} ${Cookies.get('signature')}`,
  },
  transformRequest: (data, config) => (config['Content-Type'] === 'application/json' ? JSON.stringify(data) : data),
  withCredentials: true,
};

const api = new Axios(axiosConfig);

api.interceptors.request.use(
  (requestConfig) => {
    stores.globalLoaderStore.activate();
    return requestConfig;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (response: any) => {
    stores.globalLoaderStore.deactivate();

    const data = typeof response.data === 'string' && response.data ? JSON.parse(response.data) : response.data;

    if (response.status >= 200 && response.status < 400) {
      return { data: data || null, error: null };
    }

    if (response.status >= 400 || data?.status === 'error') {
      return handleError(response, data?.error_message);
    }

    return response;
  },
  async (error: AxiosError) => {
    stores.globalLoaderStore.deactivate();

    return handleError(error);
  },
);

export { api };
