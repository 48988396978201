import { makeAutoObservable, observable } from 'mobx';

import { getWindowDimensions } from 'shared/helpers';

export class UiStore {
  public windowDimensions = getWindowDimensions();

  constructor() {
    makeAutoObservable(this, { windowDimensions: observable.struct });
    window.onresize = () => {
      this.windowDimensions = getWindowDimensions();
    };
  }
}
