export const customErrors = {
  connectWalletError: 'Connect wallet error',
  walletNotFound: 'Wallet not found',
  signInError: 'Sign in error',
  unknownError: 'Unknown error',
  nftLoadingError: 'NFT loading error',
  noContractAddress: 'Missing contract address',
  transactionError: 'Transaction error',
  noImageData: 'Missing image data',
};
