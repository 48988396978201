import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';

import { BreadCrumbs, FormInput, Logo } from 'shared/components';
import { Profile, RouteType, SCREEN } from 'shared/types';

import S from './Header.module.scss';
import { useRoutes } from './helpers/useRoutes.hook';
import { NavMenu } from './NavMenu';

type Props = {
  routes: RouteType[];
  isAuthorized: boolean;
  profile: Profile | null;
  connect(): void;
  disconnect(): void;
};

const Header: React.FC<Props> = ({ routes, isAuthorized, profile, connect, disconnect }) => {
  const { currentRoute, isRootPage, matchedRoutes } = useRoutes(routes);

  const theme = isRootPage ? 'light' : 'dark';
  const elementsTheme = isRootPage ? 'dark' : 'light';

  const isMobile = useMedia(`(max-width: ${SCREEN.SM}px)`);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);

  const navigate = useNavigate();

  const handleSearchInputSubmit = (query: string) => {
    const searchParam = query ? `?text=${encodeURIComponent(query)}` : '';
    navigate(`/search${searchParam}`);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsMenuVisible(false);
      setIsSearchInputVisible(true);
    } else {
      setIsSearchInputVisible(false);
    }
  }, [isMobile]);

  return (
    <header className={cx(S.root, S[`${theme}Theme`])}>
      <div className={cx(S.topline, { [S.onRootPage]: isRootPage })}>
        <div
          className={cx(S.container, {
            [S.containerFixed]: isMenuVisible,
          })}
        >
          <div className={S.containerRow}>
            <Logo theme={elementsTheme} asLink={!isRootPage} />
            {isMobile && (
              <div className={S.iconGroup}>
                <Icon
                  className={cx(S.icon, S.iconSearch, S[`${elementsTheme}Theme`], {
                    [S.hidden]: isSearchInputVisible,
                  })}
                  name="search"
                  onClick={() => setIsSearchInputVisible(!isSearchInputVisible)}
                />
                <Icon
                  className={cx(S.icon, S.iconMenu, S[`${elementsTheme}Theme`])}
                  name={isMenuVisible ? 'close' : 'bars'}
                  onClick={() => setIsMenuVisible(!isMenuVisible)}
                />
              </div>
            )}
          </div>
          <FormInput
            className={cx(S.searchInput, { [S.hidden]: !isSearchInputVisible })}
            placeholder="Search here"
            theme={elementsTheme}
            onSubmit={(query) => handleSearchInputSubmit(query)}
          />
        </div>
        {isMobile ? (
          <Sidebar
            className={cx(S.sidebar, S[`${elementsTheme}Theme`])}
            as={Menu}
            animation="push"
            icon="labeled"
            visible={isMenuVisible}
            vertical
            inverted
            borderless
          >
            <NavMenu
              theme={theme}
              isAuthorized={isAuthorized}
              profile={profile}
              connect={connect}
              disconnect={disconnect}
              close={() => setIsMenuVisible(false)}
            />
          </Sidebar>
        ) : (
          <NavMenu
            theme={theme}
            isAuthorized={isAuthorized}
            profile={profile}
            connect={connect}
            disconnect={disconnect}
          />
        )}
      </div>
      {!isRootPage && currentRoute?.metadata && (
        <section className={S.info}>
          <h1 className={S.title}>{currentRoute.metadata.title}</h1>
          <BreadCrumbs routes={matchedRoutes} />
        </section>
      )}
    </header>
  );
};

export { Header };
