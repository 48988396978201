export function truncate(address: string, startQuantity = 6, endQuantity = 5) {
  const start = address.substring(0, startQuantity);
  const end = address.substring(address.length - endQuantity);

  return `${start}...${end}`;
}

export function awaitTimeout(delay = 1500) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function getWindowDimensions(): Record<'width' | 'height', number> {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function getRandomIndex(length: number): number {
  return Math.floor(Math.random() * length);
}
