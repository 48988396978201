import * as R from 'ramda';
import { memo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntersection } from 'react-use';

import api from 'services/api';
import type { Artist } from 'shared/types';

import { ArtistCard } from './ArtistCard';
import S from './FeaturedArtists.module.scss';

const mockArtists = R.range(1, 13).map((id) => ({ id }));

const Component = () => {
  const [artists, setArtists] = useState<Artist[] | null>(null);

  const rootRef = useRef(null);
  const intersection = useIntersection(rootRef, {});
  const isSectionActive = Boolean(intersection?.isIntersecting);

  useEffect(
    function loadFeaturedArtists() {
      if (!isSectionActive) return;

      (async function IIFE() {
        const requestsList = await api.artists.loadFeaturedArtists();
        if (requestsList !== null) {
          setArtists(requestsList);
        }
      })();
    },
    [isSectionActive],
  );

  return (
    <section className={S.root} ref={rootRef}>
      <div className={S.header}>
        <h2 className={S.title}>Featured Artists</h2>
        <Link className={S.link} to="/artists">
          View all
        </Link>
      </div>
      <div className={S.artists}>
        {artists &&
          artists.map((artist) => (
            <div className={S.artist} key={artist.id}>
              <ArtistCard name={artist.name} avatar={artist.avatar} cover={artist.cover} link={artist.link} />
            </div>
          ))}
        {!artists &&
          mockArtists.map((artist) => (
            <div className={S.artist} key={artist.id}>
              <ArtistCard isLoading />
            </div>
          ))}
      </div>
    </section>
  );
};

export const FeaturedArtists = memo(Component);
FeaturedArtists.displayName = 'FeaturedArtists';
