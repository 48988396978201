export const TEXT_INPUT_MAX_LENGTH = 50;
export const TEXTAREA_MAX_LENGTH = 500;
export const NUMERIC_INPUT_MAX_LENGTH = 7;

export const ONE_KB = 1024;
export const ONE_MB = 1024 * 1024;

export const MAX_FILE_SIZE = 100; // unit: Mb
export const NFT_IMAGE_SIZE = { width: 350, height: 350 }; // unit: px

export const FILE_FORMATS: Record<File['type'], string> = {
  image: 'PNG, JPG or GIF',
};

export const NO_BREAK_SPACE = '\u00A0';
