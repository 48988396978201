import { ONE_KB } from '../constants';

export function convertBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return null;
  }

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(ONE_KB));

  return `${parseFloat((bytes / ONE_KB ** i).toFixed(dm))} ${sizes[i]}`;
}
