export const calculateOffset = (value: number | number[], min: number, max: number) => {
  const calculate = (val: number) => `${((val - min) / (max - min)) * 100}%`;

  if (Array.isArray(value)) {
    return {
      leftOffset: calculate(value[0]),
      rightOffset: calculate(value[1]),
    };
  }

  return {
    leftOffset: '0%',
    rightOffset: calculate(value),
  };
};
