import { Icon } from 'semantic-ui-react';

import S from './Likes.module.scss';

type Props = {
  amount: number;
};

export const Likes = ({ amount }: Props) => {
  return (
    <div className={S.root}>
      <Icon name="heart outline" className={S.icon} />
      <div className={S.amount}>{amount}</div>
    </div>
  );
};
