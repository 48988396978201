import { Contract } from 'alchemy-sdk';
import { BigNumber, utils } from 'ethers';
import { getAccount } from 'wagmi/actions';

import { stores, wagmiClient } from 'core';
import { handleError, customErrors } from 'services/api/helpers';
import { Response } from 'services/api/types';
import { config } from 'shared/helpers';
import { NftOrder } from 'shared/types';

import { marketAbi, wethAbi } from '../abi';
import { sendTransaction } from '../helpers/sendTransaction';
import { AbiItem } from '../types';

export async function signOrder(order: NftOrder): Promise<Response<string>> {
  try {
    const { ethereum } = window;

    // TODO: improve after subscribing to a network switching event
    const { connector } = wagmiClient;
    const supportedChainId = connector?.chains[0]?.id;

    const types = {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      Order: [
        { name: 'maker', type: 'address' },
        { name: 'taker', type: 'address' },
        { name: 'collection', type: 'address' },
        { name: 'currency', type: 'address' },
        { name: 'price', type: 'uint256' },
        { name: 'tokenId', type: 'uint256' },
        { name: 'start', type: 'uint256' },
        { name: 'end', type: 'uint256' },
        { name: 'params', type: 'bytes' },
      ],
    };
    const primaryType = 'Order';
    const domain = {
      name: 'Market',
      version: '1',
      chainId: supportedChainId,
      verifyingContract: config.marketAddress,
    };

    const signature = (await ethereum.request({
      method: 'eth_signTypedData_v4',
      params: [ethereum.selectedAddress, { types, primaryType, domain, message: order }],
    })) as string;

    return { data: signature, error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function getBalance() {
  try {
    const { address } = getAccount();
    const signer = await wagmiClient.connector?.getSigner();
    const contract = new Contract(config.defaultCurrencyAddress, wethAbi, signer);
    const balance = (await contract.functions.balanceOf(address)) as BigNumber[];
    return { data: balance[0], error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function depositCurrency(amount: BigNumber) {
  try {
    stores.globalLoaderStore.activate();

    const { ethereum } = window;
    const signer = await wagmiClient.connector?.getSigner();
    const contract = new Contract(config.defaultCurrencyAddress, wethAbi, signer);

    const txParams = {
      from: ethereum.selectedAddress,
      to: config.defaultCurrencyAddress,
      data: contract.interface.encodeFunctionData('deposit'),
      value: amount.toHexString(),
    };

    const txHash = (await ethereum.request({
      method: 'eth_sendTransaction',
      params: [txParams],
    })) as string;

    const { status } = await wagmiClient.provider.waitForTransaction(txHash);

    if (status !== 1) {
      throw new Error(customErrors.transactionError);
    }
    return { data: txHash, error: null };
  } catch (error) {
    return handleError(error as Error);
  } finally {
    stores.globalLoaderStore.deactivate();
  }
}

export async function setAllowance(amount: BigNumber) {
  return sendTransaction({
    address: config.defaultCurrencyAddress,
    abi: wethAbi as AbiItem[],
    functionName: 'approve',
    args: [config.marketAddress, amount],
  });
}

export async function getAllowance() {
  try {
    const { address } = getAccount();
    const operatorAddress = config.marketAddress;
    const signer = await wagmiClient.connector?.getSigner();
    const contract = new Contract(config.defaultCurrencyAddress, wethAbi, signer);
    const allowance = (await contract.functions.allowance(address, operatorAddress)) as BigNumber[];
    return { data: allowance[0], error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function fulfillOrder(order: NftOrder, signature: string) {
  return sendTransaction({
    address: config.marketAddress,
    abi: marketAbi as AbiItem[],
    functionName: 'fulfillOrder',
    args: [{ ...order, price: String(order.price) }, signature],
  });
}
