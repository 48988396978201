import cx from 'classnames';
import { ControllerRenderProps } from 'react-hook-form';
import { TextArea as SemanticTextArea } from 'semantic-ui-react';

import { TEXTAREA_MAX_LENGTH } from 'shared/helpers';

import S from './TextArea.module.scss';

type Props = {
  value: ControllerRenderProps['value'];
  invalid: boolean;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  onChange: ControllerRenderProps['onChange'];
};

const TextArea: React.FC<Props> = ({
  value,
  invalid,
  className,
  placeholder,
  maxLength = TEXTAREA_MAX_LENGTH,
  rows = 4,
  onChange,
}) => {
  return (
    <SemanticTextArea
      className={cx(S.root, { [S.rootInvalid]: invalid }, className)}
      value={value}
      placeholder={placeholder}
      rows={rows}
      maxLength={maxLength}
      onChange={onChange}
    />
  );
};

export { TextArea };
