import { Link } from 'react-router-dom';

import { Theme } from 'shared/types';

import LogoDark from './assets/logo-dark';
import LogoLight from './assets/logo-light';

type Props = {
  theme?: Theme;
  asLink?: boolean;
};

const Logo: React.FC<Props> = ({ theme = 'dark', asLink = true }) => {
  const LogoElement = theme === 'dark' ? <LogoDark /> : <LogoLight />;

  return asLink ? <Link to="/">{LogoElement}</Link> : LogoElement;
};

export { Logo };
