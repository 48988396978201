import cx from 'classnames';
import { useRef } from 'react';
import { Navigation, EffectCoverflow, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import S from './SecondarySlider.module.scss';
// TODO: ask navigation additional css only if necessary
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css';

type PartOfSwiperOptions = Pick<
  SwiperOptions,
  | 'centeredSlides'
  | 'watchSlidesProgress'
  | 'initialSlide'
  | 'breakpoints'
  | 'slidesPerView'
  | 'slidesPerGroup'
  | 'spaceBetween'
  | 'coverflowEffect'
  | 'autoplay'
  | 'speed'
  | 'loop'
>;

export type SliderConfig = PartOfSwiperOptions & {
  navigation?: boolean;
  effect?: 'coverflow';
  navigationContainerClass?: string;
};

type Props = React.PropsWithChildren<{
  className?: string;
  config: SliderConfig;
}>;

export const SecondarySlider = (props: Props) => {
  const { className, config, children } = props;

  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  const modules = [];
  if (config.navigation) modules.push(Navigation);
  if (config.effect === 'coverflow') modules.push(EffectCoverflow);

  const navigation = config.navigation && {
    prevEl: prevButtonRef.current || null,
    nextEl: nextButtonRef.current || null,
    disabledClass: S.navButtonDisabled,
  };

  return (
    <Swiper
      className={className}
      speed={config.speed}
      autoplay={config.autoplay}
      initialSlide={config.initialSlide}
      breakpoints={config.breakpoints}
      slidesPerView={config.slidesPerView}
      spaceBetween={config.spaceBetween}
      slidesPerGroup={config.slidesPerGroup}
      watchSlidesProgress={config.watchSlidesProgress}
      centeredSlides={config.centeredSlides}
      navigation={navigation}
      effect={config.effect}
      coverflowEffect={config.coverflowEffect}
      modules={modules}
      loop={config.loop}
    >
      {children}
      {config.navigation && (
        <div className={cx(S.navigation, config.navigationContainerClass)}>
          <button className={cx(S.buttonPrev, 'slider-button-prev')} ref={prevButtonRef} type="button">
            <i className={cx(S.iconArrow, 'icon icon-arrow')} />
          </button>
          <button className={cx(S.buttonNext, 'slider-button-next')} ref={nextButtonRef} type="button">
            <i className={cx(S.iconArrow, 'icon icon-arrow')} />
          </button>
        </div>
      )}
    </Swiper>
  );
};

SecondarySlider.Slide = SwiperSlide;
