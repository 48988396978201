/* eslint-disable max-len */

const LogoLight = () => (
  <svg width="140" height="26" viewBox="0 0 140 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.4685 21.7203C43.8868 22.6494 43.2243 23.5155 42.481 24.3186C39.2654 27.7987 34.6601 30.0348 29.5216 30.1608L26.5322 18.8859L36.0336 13.6421L44.4685 21.7203Z"
      fill="url(#paint0_linear_1365_3421)"
    />
    <path d="M68.4485 0.85498H65.0713V18.5547H77.9337V15.7675H68.4485V0.85498Z" fill="white" />
    <path
      d="M90.4736 6.69702C89.3587 5.4845 87.8074 4.90186 86.03 4.90186C81.9903 4.90186 79.0332 7.61035 79.0332 11.7991C79.0332 16.0035 81.9903 18.7278 86.03 18.7278C87.9206 18.7278 89.5364 18.0979 90.6191 16.8067V18.5546H93.7054V5.05933H90.4575V6.69702H90.4736ZM86.4016 16.0193C84.0586 16.0193 82.2973 14.3816 82.2973 11.7991C82.2973 9.21656 84.0586 7.57886 86.4016 7.57886C88.7608 7.57886 90.5221 9.21656 90.5221 11.7991C90.5221 14.3816 88.7608 16.0193 86.4016 16.0193Z"
      fill="white"
    />
    <path
      d="M115.455 4.90186C113.257 4.90186 111.383 5.76795 110.268 7.15369C109.314 5.64197 107.618 4.90186 105.598 4.90186C103.707 4.90186 102.091 5.56323 101.057 6.77576V5.05933H97.9707V18.5546H101.219V11.7046C101.219 9.02759 102.673 7.6576 104.822 7.6576C106.793 7.6576 107.941 8.82288 107.941 11.2164V18.5546H111.172V11.7046C111.172 9.02759 112.659 7.6576 114.776 7.6576C116.747 7.6576 117.895 8.82288 117.895 11.2164V18.5546H121.142V10.8228C121.126 6.74426 118.799 4.90186 115.455 4.90186Z"
      fill="white"
    />
    <path
      d="M132.97 4.90186C131.08 4.90186 129.464 5.53174 128.381 6.823V5.05933H125.295V23.4677H128.543V16.9169C129.658 18.1609 131.209 18.7435 132.987 18.7435C137.026 18.7435 140.016 16.0193 140.016 11.8148C139.999 7.6261 137.01 4.90186 132.97 4.90186ZM132.599 16.0193C130.272 16.0193 128.478 14.3816 128.478 11.7991C128.478 9.21656 130.272 7.57886 132.599 7.57886C134.926 7.57886 136.703 9.21656 136.703 11.7991C136.703 14.3816 134.942 16.0193 132.599 16.0193Z"
      fill="white"
    />
    <path
      d="M17.0637 0.82373L9.98615 12.5553L2.77932 0.82373H0V18.5235H3.2156V6.94935L9.17821 16.4921H10.681L16.6597 6.79188L16.6759 18.5235H19.8753L19.843 0.82373H17.0637Z"
      fill="white"
    />
    <path
      d="M58.9307 6.66577C57.8157 5.45325 56.2645 4.87061 54.487 4.87061C50.4473 4.87061 47.4902 7.5791 47.4902 11.7678C47.4902 15.9723 50.4473 18.6965 54.487 18.6965C56.3776 18.6965 57.9935 18.0667 59.0761 16.7754V18.5233H62.1624V5.02808H58.9145V6.66577H58.9307ZM54.8587 15.988C52.5318 15.988 50.7543 14.3503 50.7543 11.7678C50.7543 9.18531 52.5156 7.54761 54.8587 7.54761C57.2178 7.54761 58.9792 9.18531 58.9792 11.7678C58.9792 14.3503 57.2178 15.988 54.8587 15.988Z"
      fill="white"
    />
    <path
      d="M26.0965 6.28817L27.0499 7.89437C27.3731 7.92587 27.6639 8.08334 27.8578 8.35104C29.3929 7.92587 30.9119 8.44552 31.9137 9.72103L33.5134 11.2485L35.6302 10.0675C35.501 9.79977 35.3232 9.45333 35.194 9.23287C33.1418 5.73702 29.4576 4.71346 26.0965 6.28817ZM22.8809 16.0356C23.7858 17.5631 25.0785 18.6339 26.5975 19.1378L25.1108 13.5161C24.513 12.0044 24.82 10.4926 25.9188 9.42184C25.7734 9.12264 25.7734 8.79196 25.9188 8.49276L24.9816 6.9023C21.8629 9.01241 20.9257 12.7445 22.8809 16.0356Z"
      fill="white"
    />
    <path
      d="M36.6316 14.2084C36.163 15.8461 34.9188 17.2476 33.0605 18.3026C30.7983 19.5781 28.536 19.7986 26.597 19.1372L25.1104 13.5155L31.9132 9.68896L33.5129 11.2164L26.2092 15.3264C27.5665 16.8381 29.6671 17.1216 31.7516 15.9406C33.0928 15.1847 33.9169 14.1769 34.2562 12.8384C34.337 12.5707 34.3694 12.2872 34.3855 12.051L36.6316 14.2084Z"
      fill="#FFA300"
    />
    <path
      d="M43.7089 16.0978C42.497 16.0978 41.786 15.3892 41.786 14.0822V7.64161H45.5025V5.12207H41.786V2.03564H38.5381V14.1452C38.5381 17.1214 40.2832 18.6961 43.3049 18.6961C44.4684 18.6961 45.648 18.3969 46.4236 17.7827L45.5187 15.5309C45.0339 15.9088 44.3876 16.0978 43.7089 16.0978Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1365_3421"
        x1="31.7142"
        y1="16.7192"
        x2="36.4979"
        y2="25.5155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA300" stopOpacity="0.8" />
        <stop offset="1" stopColor="#FFA300" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoLight;
