import Cookies from 'js-cookie';

import { api } from 'services/api/client';
import { Response } from 'services/api/types';
import { NftOrder } from 'shared/types';

export async function createOrder(data: NftOrder & { signature: string }) {
  const response = await api.post<NftOrder, Response<NftOrder>>(`/api/rest/orders/`, data);
  return response;
}

export async function deleteOrder(orderId: number) {
  const response = await api.delete<NftOrder, Response<NftOrder>>(`/api/rest/orders/${orderId}`);
  return response;
}
