import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStores } from 'core';
import { WalletCard } from 'features/auth';

import S from './ConnectWallet.module.scss';

const ConnectWallet = observer(() => {
  const {
    authStore: { isAuthorized },
  } = useStores();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      const from = state?.from || '/profile';
      navigate(from, { replace: true });
    }
  }, [isAuthorized, state?.from, navigate]);

  return (
    <section className={S.root}>
      <WalletCard type="metamask" />
      <WalletCard type="default" />
    </section>
  );
});

export { ConnectWallet };
