import { Address } from 'wagmi';

export const config = {
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  ipfsUrl: process.env.REACT_APP_BACKEND_IPFS_URL,
  ipfsKey: process.env.REACT_APP_PINATA_ACCESS_KEY,
  alchemyApiUrl: process.env.REACT_APP_ALCHEMY_API_URL,
  alchemyApiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  collectionFactoryAddress: process.env.REACT_APP_FACTORY_ADDRESS as Address,
  defaultCollectionAddress: process.env.REACT_APP_METALAMP_ADDRESS as Address,
  marketAddress: process.env.REACT_APP_MARKET_ADDRESS as Address,
  defaultCurrencyAddress: process.env.REACT_APP_WETH_ADDRESS as Address,
  authExpirationDays: 3,
};
