import { AxiosError } from 'axios';

import { customErrors } from './customErrors';
import { ErrorResponse, Error } from '../types';

function isError(response: Error): response is AxiosError {
  return 'message' in response;
}

export function handleError(response: Error, errorMessage?: string): ErrorResponse {
  const responseMsg = isError(response) ? response.message : null;
  const message: string = responseMsg ?? errorMessage ?? customErrors.unknownError;
  console.error(message); // TODO: add error handling

  return { data: null, error: { status: response.status, message } };
}
