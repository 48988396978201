import { isPriceRange } from './typeGuards';
import { PriceFilter } from '../../../../types';

export const arePricesEqual = (price1?: PriceFilter, price2?: PriceFilter) => {
  if (isPriceRange(price1) && isPriceRange(price2)) {
    return price1.from === price2.from && price1.to === price2.to;
  }

  return price1 === price2;
};
