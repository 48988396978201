import { useCallback } from 'react';
import { useNetwork, useSwitchNetwork } from 'wagmi';

export const useNetworkSwitching = () => {
  const { chain, chains } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();

  const switchToDefaultNetwork = useCallback(async () => {
    if (chain?.unsupported && switchNetworkAsync) {
      await switchNetworkAsync(chains[0].id);
    }
  }, [chain, chains, switchNetworkAsync]);

  return { switchToDefaultNetwork };
};
