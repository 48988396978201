import { observer } from 'mobx-react-lite';
import { Modal } from 'semantic-ui-react';

import { useStore } from 'features/assetInfo/store/useStore';
import { ListNftForm } from 'features/listNft/views/ListNftForm';

import S from './ListNftModal.module.scss';

type Props = {
  isVisible: boolean;
  onClose(): void;
};

export const ListNftModal: React.FC<Props> = observer(({ isVisible, onClose }) => {
  const { nft, loadNft } = useStore();

  const handleClose = () => {
    onClose();
  };

  const handleFormSubmit = () => {
    onClose();
    if (nft) loadNft(nft.primary_id);
  };

  return (
    <Modal className={S.root} open={isVisible} closeIcon size="tiny" onClose={handleClose}>
      <Modal.Content className={S.content}>
        <h3 className={S.header}>Put NFT on sale</h3>
        <Modal.Description className={S.description}>
          {nft && <ListNftForm tokenId={nft.id} onSubmit={handleFormSubmit} />}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
});
