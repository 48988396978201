import { BigNumber } from 'ethers';
import { makeAutoObservable, runInAction } from 'mobx';

import api from 'services/api';
import { toast } from 'shared/components';
import { NftOrder } from 'shared/types';

export class BuyNftStore {
  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  buyNft = async (order: NftOrder, signature: string) => {
    const { price } = order;
    const priceBig = BigNumber.from(String(price));

    const { data: balance } = await api.orders.getBalance();
    if (balance?.lt(priceBig)) {
      await api.orders.depositCurrency(priceBig.sub(balance));
    }

    const { data: allowance } = await api.orders.getAllowance();
    if (allowance?.lt(priceBig)) {
      await api.orders.setAllowance(priceBig);
    }

    const response = await api.orders.fulfillOrder(order, signature);
    if (response.data) {
      toast.success('NFT is successfully purchased');
    } else {
      toast.error('An error occurred while buying NFT');
    }
  };
}
