import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStores } from 'core';
import { Filters, Results, TextFilter } from 'features/filterAssets';
import { useIsTablet } from 'shared/helpers';

import S from './Search.module.scss';

export const Search = observer(() => {
  const { filter, noResults, setup, cleanup, updateFilter } = useStores().filterAssetsStore;
  const isTablet = useIsTablet();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const text = decodeURIComponent(searchParams.get('text') ?? '');
    updateFilter({ text });
  }, [searchParams, updateFilter]);

  useEffect(() => {
    setup('all');
    return cleanup;
  }, [setup, cleanup]);

  const handleTextFilterChange = (text: string) => {
    updateFilter({ text });
    setSearchParams((prevParams) => {
      if (text) {
        prevParams.set('text', encodeURIComponent(text));
      } else {
        prevParams.delete('text');
      }
      return prevParams;
    });
  };

  return (
    <div className={S.root}>
      <Filters enabledFilters={['status', 'price']} />
      <div className={S.results}>
        <TextFilter text={filter.text} onChange={handleTextFilterChange} />
        {noResults && <h3>Sorry, no assets were found for your request :(</h3>}
        <Results assetType="collections" title="Collection Results" isCarousel />
        <Results assetType="nfts" title="NFTs Results" isCarousel={isTablet} />
      </div>
    </div>
  );
});
