import arbitrumImage from 'assets/images/arbitrum.svg';
import coinbaseImage from 'assets/images/coinbase.svg';
import cryptonewsImage from 'assets/images/cryptonews.svg';
import ethereumImage from 'assets/images/ethereum.svg';
import polygonImage from 'assets/images/polygon.svg';
import roninImage from 'assets/images/ronin.svg';

export const partners = [
  {
    name: 'coinbase',
    image: coinbaseImage,
  },
  {
    name: 'arbitrum',
    image: arbitrumImage,
  },
  {
    name: 'cryptonews',
    image: cryptonewsImage,
  },
  {
    name: 'ethereum',
    image: ethereumImage,
  },
  {
    name: 'ronin',
    image: roninImage,
  },
  {
    name: 'polygon',
    image: polygonImage,
  },
];
