import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import defaultAvatar from 'services/api/fake/assets/avatar.png';
import { CollapsedText } from 'shared/components';
import { ServerProfile } from 'shared/types';

import S from './AssetDescription.module.scss';
import { AvatarWallet } from '../AvatarWallet';

type Props = {
  name?: string;
  description?: string;
  creator?: ServerProfile;
  owner?: ServerProfile;
  clubName?: string; // TODO: remove this field?
};

export const AssetDescription: React.FC<PropsWithChildren<Props>> = ({
  name,
  description,
  creator,
  owner,
  clubName = 'Billionaires NFT Club', // TODO: remove this field?
  children,
}) => {
  return (
    <div className={S.root}>
      {clubName && (
        <div className={S.club}>
          <div>by</div>
          <div className={S.author}>{clubName}</div>
        </div>
      )}
      <div className={S.name}>{name}</div>
      <CollapsedText className={S.description} text={description ?? "unknown"} rowLimit={3} isExpandable />
      <div className={S.users}>
        {creator && (
          <div>
            <div className={S.userTitle}>Creator</div>
            <Link to={`/profile/${creator.address}`}>
              <AvatarWallet userAvatar={creator.avatar ?? defaultAvatar} wallet={creator.address} />
            </Link>
          </div>
        )}
        {owner && (
          <div>
            <div className={S.userTitle}>Current Owner</div>
            <Link to={`/profile/${owner.address}`}>
              <AvatarWallet userAvatar={owner.avatar ?? defaultAvatar} wallet={owner.address} />
            </Link>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
