import cn from 'classnames';
import { Table } from 'semantic-ui-react';

import { ReactComponent as EthIcon } from 'assets/icons/eth.svg';

import S from './SalesHistory.module.scss';
import { DescriptionCard } from '../DescriptionCard';

const data = [
  { price: 0.001, usdPrice: '$385.95', floorDifference: '31% below', expiration: '11 min', from: 'yes_nft' },
  { price: 0.001, usdPrice: '$385.95', floorDifference: '31% below', expiration: '11 min', from: 'yes_nft' },
  { price: 0.001, usdPrice: '$385.95', floorDifference: '31% below', expiration: '11 min', from: 'yes_nft' },
  { price: 0.001, usdPrice: '$385.95', floorDifference: '31% below', expiration: '11 min', from: 'yes_nft' },
  { price: 0.001, usdPrice: '$385.95', floorDifference: '31% below', expiration: '11 min', from: 'yes_nft' },
];

export const SalesHistory = () => {
  return (
    <DescriptionCard title="Sales History">
      <Table className={S.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={S.headerCell}>Price (ETH)</Table.HeaderCell>
            <Table.HeaderCell className={S.headerCell}>USD Price</Table.HeaderCell>
            <Table.HeaderCell className={S.headerCell}>Floor Difference</Table.HeaderCell>
            <Table.HeaderCell className={S.headerCell}>Expiration</Table.HeaderCell>
            <Table.HeaderCell className={S.headerCell}>From</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((row, index) => (
            <Table.Row key={index}>
              <Table.Cell className={S.cell}>
                <div className={S.priceCell}>
                  <EthIcon />
                </div>
                <div className={S.priceCell}>{row.price} ETH</div>
              </Table.Cell>
              <Table.Cell className={S.cell}>{row.usdPrice}</Table.Cell>
              <Table.Cell className={S.cell}>{row.floorDifference}</Table.Cell>
              <Table.Cell className={S.cell}>{row.expiration}</Table.Cell>
              <Table.Cell className={cn(S.cell, S.fromCell)}>{row.from}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </DescriptionCard>
  );
};
