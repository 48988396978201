import Cookies from 'js-cookie';
import * as R from 'ramda';
import { Address } from 'wagmi';

import { Profile, ServerProfile } from 'shared/types';

import { api } from '../../client';
import { fakeResponse, mockProfileMetadata } from '../../fake';
import { convertProfile } from '../../helpers';
import { Response } from '../../types';

export async function load<T extends ServerProfile>(
  address: Address | undefined,
): Promise<Response<ServerProfile | null>> {
  const response = await api.get<T, Response<T>>(`/api/rest/users/${address}/`);

  if (response.data) {
    return { data: convertProfile(response.data), error: null };
  }

  return response;
}

export async function update(data: Profile, address: Address | undefined): Promise<Response<null>> {
  const { avatar, ...profileInfo } = data;

  const formData = new FormData();
  Object.entries(R.omit(['id', 'user', 'url'], profileInfo)).forEach(([key, value]) => {
    if (value) formData.append(key, value);
  });
  if (typeof avatar === 'object' && avatar !== null) {
    formData.append('avatar', avatar.file);
  }

  const response = await api.put<null, Response<null>>(`/api/rest/users/${address}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
}

export async function loadMetadata() {
  return fakeResponse(mockProfileMetadata);
}
