import { BigNumber, utils } from 'ethers';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

import { useStores } from 'core';
import { useStore } from 'features/assetInfo/store/useStore';
import { ListNftModal } from 'modules/ListNftModal/ListNftModal';
import { Button } from 'shared/components';

import mockData from './mockData';
import S from './NftDescription.module.scss';
import { AssetDescription } from '../../components/AssetDescription';

export const NftDescription = () => {
  const { currency, lastPrice, dollarPrice } = mockData;

  const { nft, burnNft, resetNft } = useStore();

  const [isModalVisible, setIsModalVisible] = useState(false);

  // TODO: move to BuyNftModal
  const { buyNft } = useStores().buyNftStore;
  const handleBuyButtonClick = () => {
    // TODO: choose order if multiple are present
    if (nft?.orders?.[0]) {
      const { signature, ...order } = nft.orders[0];
      buyNft(order, signature);
    }
  };

  useEffect(() => {
    return resetNft;
  }, [resetNft]);

  const { address } = useAccount();
  const isOwned = address?.toLowerCase() === nft?.owner?.address.toLowerCase();
  const isBuyable = nft?.orders && nft.orders.length > 0;

  // TODO: choose order if multiple are present
  const price = nft?.orders?.[0]?.price
    ? utils.formatUnits(BigNumber.from(String(nft.orders[0].price)), 'ether')
    : null;

  const handleBurnButtonClick = () => {
    if (nft) burnNft(nft);
  };

  if (nft) {
    return (
      <>
        <AssetDescription {...nft}>
          <div className={S.root}>
            {price && (
              <div className={S.priceInfo}>
                <div className={S.currentPrice}>Current Price</div>
                <div className={S.price}>
                  <div className={S.priceValue}>
                    ~{price} {currency}
                  </div>
                  {/* <div className={S.dollarValue}>({dollarPrice})</div> */}
                </div>
                {/* <div className={S.lastPrice}>
                Last sale price ~{lastPrice} {currency}
              </div> */}
              </div>
            )}
            <div className={S.buttons}>
              {isBuyable && (
                <Button theme="dark" fill="solid" onClick={handleBuyButtonClick}>
                  Buy now
                </Button>
              )}
              {isOwned && !isBuyable && (
                <Button theme="dark" fill="solid" onClick={() => setIsModalVisible(true)}>
                  Put on sale
                </Button>
              )}
              {isOwned && (
                <Button theme="bright" fill="solid" onClick={handleBurnButtonClick}>
                  Burn
                </Button>
              )}
            </div>
          </div>
        </AssetDescription>
        <ListNftModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
      </>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
