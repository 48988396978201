import { Image } from 'shared/types';

export function toFileUrl(source?: Blob | Image) {
  if (source instanceof Blob) {
    return URL.createObjectURL(source);
  }

  if (typeof source === 'object' && source !== null) {
    return URL.createObjectURL(source.preview ?? source.file);
  }

  return source ?? undefined;
}
