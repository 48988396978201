import { Statistic } from 'semantic-ui-react';

import S from './Statistics.module.scss';

type Props = {
  quantity: number;
  quantitySold: number;
};

export const Statistics: React.FC<Props> = ({ quantity, quantitySold }) => {
  return (
    <div className={S.root}>
      <h3 className={S.title}>Amount of NFTs</h3>
      <Statistic className={S.statistics}>
        <Statistic.Value className={S.value}>{quantity}</Statistic.Value>
        <Statistic.Label className={S.label}>
          {quantitySold}/{quantity} Sold
        </Statistic.Label>
      </Statistic>
    </div>
  );
};
