import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';

import { useStores } from 'core';

const PrivateRoute = observer(({ component }: { component: JSX.Element }) => {
  const { isAuthorized } = useStores().authStore;
  const location = useLocation();

  if (!isAuthorized) {
    return <Navigate to="/connect-wallet" state={{ from: location }} />;
  }

  return component;
});

export { PrivateRoute };
