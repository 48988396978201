import cx from 'classnames';

import { ReactComponent as LightEthIcon } from 'assets/icons/eth-light.svg';
import { ReactComponent as DarkEthIcon } from 'assets/icons/eth.svg';
import { Theme } from 'shared/types';

import S from './EthIcon.module.scss';

type Props = {
  theme?: Theme;
  className?: string;
};

const EthIcon: React.FC<Props> = ({ theme = 'dark', className }) => {
  return (
    <span className={cx(S.root, S[`${theme}Theme`], className)}>
      {theme === 'dark' ? <DarkEthIcon className={S.icon} /> : <LightEthIcon className={S.icon} />}
    </span>
  );
};

export { EthIcon };
