import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useStores } from 'core';
import { Filters, Results } from 'features/filterAssets';
import { Button } from 'shared/components';
import { useIsTablet } from 'shared/helpers';

import S from './ExploreAssets.module.scss';

type Props = {
  assetType: 'nfts' | 'collections';
};

export const ExploreAssets: React.FC<Props> = observer(({ assetType }) => {
  const { setup, cleanup } = useStores().filterAssetsStore;
  const isTablet = useIsTablet();

  useEffect(() => {
    setup(assetType);
  }, [assetType, setup]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  return (
    <div className={S.root}>
      <Filters enabledFilters={assetType === 'nfts' ? ['status', 'price'] : ['status']} />
      <div className={S.results}>
        <div className={S.buttonGroup}>
          <Button as={Link} to="/explore/nfts" fill="solid" theme={assetType === 'nfts' ? 'bright' : 'dark'}>
            NFTs
          </Button>
          <Button
            as={Link}
            to="/explore/collections"
            fill="solid"
            theme={assetType === 'collections' ? 'bright' : 'dark'}
          >
            Collections
          </Button>
        </div>
        <Results assetType={assetType} isCarousel={isTablet} />
      </div>
    </div>
  );
});
