import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';

import { wagmiClient } from 'core';
import { routes } from 'routes';

import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import 'styles/index.scss';

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <WagmiConfig client={wagmiClient}>
    <RouterProvider router={router} />
  </WagmiConfig>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
