import { BoxSize } from 'shared/types';

export function getImageSize(imgUrl: string): Promise<BoxSize> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject(new Error('Image loading error'));
    img.src = imgUrl;
  });
}
