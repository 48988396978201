import { createContext, useContext } from 'react';

import { RootStore } from './RootStore';

export const stores = new RootStore();

const StoresContext = createContext<RootStore>(stores);

export function useStores(): RootStore {
  return useContext(StoresContext);
}
