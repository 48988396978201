import { FieldType } from 'shared/components';
import {
  FILE_FORMATS,
  MAX_FILE_SIZE,
  NUMERIC_INPUT_MAX_LENGTH,
  TEXTAREA_MAX_LENGTH,
  TEXT_INPUT_MAX_LENGTH,
} from 'shared/helpers';

import { PatternType } from './types';

type Config = {
  pattern: Record<PatternType, RegExp>;
  maxLength: Partial<Record<FieldType, number>>;
  file: { size: number; formats: Record<File['type'], string> };
};

export const config: Config = {
  pattern: {
    // eslint-disable-next-line
    url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[.\!\/\\w]*))?)/gi,
    email: /\S+@\S+\.\S+/,
    integer: /^\d+$/,
    float: /^\d+(\.\d+)?$/,
    symbol: /^[A-Z]{1,6}$/,
  },
  maxLength: {
    text: TEXT_INPUT_MAX_LENGTH,
    textarea: TEXTAREA_MAX_LENGTH,
    number: NUMERIC_INPUT_MAX_LENGTH,
  },
  file: {
    size: MAX_FILE_SIZE,
    formats: FILE_FORMATS,
  },
};
