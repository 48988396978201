import cx from 'classnames';

import { Avatar, CollapsedText } from 'shared/components';
import { toFileUrl } from 'shared/helpers';
import { Image } from 'shared/types';

import S from './CardHeader.module.scss';

type Props = {
  title: string;
  avatar?: Image;
  username?: string;
  theme?: 'light' | 'dark';
};

const CardHeader: React.FC<Props> = ({ title, avatar, username, theme = 'light' }) => {
  const avatarSrc = toFileUrl(avatar);

  return (
    <div className={cx(S.root, S[`${theme}Theme`])}>
      <Avatar src={avatarSrc} title={username} size="xxs" />
      <div className={S.content}>
        <CollapsedText className={S.title} text={title} rowLimit={1} />
        {username && <CollapsedText className={S.username} text={username} rowLimit={2} />}
      </div>
    </div>
  );
};

export { CardHeader };
