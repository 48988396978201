import cx from 'classnames';
import { Link } from 'react-router-dom';

import { CardCarousel, NftCard } from 'shared/components';
import { useIsMobile } from 'shared/helpers';
import { Nft } from 'shared/types';

import S from './AssetCardList.module.scss';

type Props = {
  title: string;
  nfts: Nft[];
  link?: { url: string; text: string };
};

export const AssetCardList: React.FC<Props> = ({ title, nfts, link }) => {
  const isMobile = useIsMobile();

  const list = nfts.map((nft) => (
    <div className={cx({ [S.card]: !isMobile })} key={nft.id}>
      <NftCard
        title={nft.name}
        price={nft.price}
        imageUrl={nft.image}
        username={nft.creator?.username ?? ''}
        avatar={nft.creator?.avatar}
      />
    </div>
  ));

  return (
    <div className={S.root}>
      <div className={S.title}>{title}</div>
      {link && (
        <Link className={S.link} to={link.url}>
          {link.text}
        </Link>
      )}
      <div className={S.nfts}>{isMobile ? <CardCarousel>{list}</CardCarousel> : list}</div>
    </div>
  );
};
