import * as R from 'ramda';
import { Address } from 'wagmi';

import { awaitTimeout, config } from 'shared/helpers';
import {
  CollectionMetadata,
  HotCollectionItem,
  NewNftCollection,
  Nft,
  NftCollection,
  ServerNft,
  ServerNftCollection,
} from 'shared/types';

import { api } from '../../client';
import { loadUri } from '../../contracts/entities/collection';
import { fakeResponse, getMockHotCollectionItem, getMockNftCollection } from '../../fake';
import { convertAsset, customErrors, handleError } from '../../helpers';
import { Response, ResponseStatus } from '../../types';

type CreateTokenUriResponse = {
  status: ResponseStatus;
  collection_uri: string;
};

export async function createTokenUri<T extends CreateTokenUriResponse>({
  name,
  description,
  image,
}: NewNftCollection): Promise<Response<string>> {
  try {
    if (image === null) {
      throw new Error(customErrors.noImageData);
    }

    const { coords, file } = image;
    const formData = new FormData();
    const metadata = {
      name,
      description,
      box: coords && [coords.x1, coords.y1, coords.x2, coords.y2],
    };
    formData.append('metadata', JSON.stringify(metadata));
    formData.append('image', file);

    const response = await api.post<T, Response<T>>('/api/collection/create', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.error) {
      return response;
    }

    return { data: response.data.collection_uri, error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

async function loadAllNftsInCollection<T extends { nfts: ServerNft[] }>(address: Address): Promise<Response<Nft[]>> {
  const response = await api.post<T, Response<T>>('/index/metadata/batch/nfts_in_collection', {
    collection_contract_address: address,
  });

  if (response.error) {
    return response;
  }

  return { data: response.data.nfts.map(convertAsset), error: null };
}

export async function loadList<T extends { collections: ServerNftCollection[] }>(
  withNfts = false,
): Promise<Response<NftCollection[]>> {
  const response = await api.post<T, Response<T>>('/index/metadata/batch/collections_in_factory', {
    factory_address: config.collectionFactoryAddress,
  });

  if (response.error) {
    return response;
  }

  const collections = await Promise.all(
    response.data.collections.map(async (collection) => ({
      ...convertAsset(collection),
      nfts: withNfts ? (await loadAllNftsInCollection(collection.contract_address)).data ?? [] : undefined,
    })),
  );

  return {
    data: collections,
    error: null,
  };
}

type LoadCollectionArgs = {
  address: Address;
  uri?: string;
  withNfts?: boolean;
};

type LoadCollectionResponse = {
  status: ResponseStatus;
  collection: ServerNftCollection;
};

export async function load<T extends LoadCollectionResponse>({
  address,
  uri,
  withNfts = false,
}: LoadCollectionArgs): Promise<Response<NftCollection>> {
  try {
    const collectionUri = uri ?? (await loadUri(address)).data;
    const response = await api.post<T, Response<T>>('/index/metadata/item/collection', {
      collection_contract_address: address,
      collection_uri: collectionUri,
    });

    if (response.error) {
      return response;
    }

    const { collection } = response.data;
    const data = {
      ...convertAsset(collection),
      nfts: withNfts ? (await loadAllNftsInCollection(collection.contract_address)).data ?? [] : undefined,
    };

    return { data, error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function loadExploreCollections(): Promise<Response<NftCollection[]>> {
  const collections = await Promise.all(R.range(1, 13).map((id) => getMockNftCollection(String(id))));
  return fakeResponse(collections, true);
}

export async function loadHotCollection(): Promise<HotCollectionItem[]> {
  await awaitTimeout();
  return R.range(1, 13).map((id) => getMockHotCollectionItem(String(id)));
}

export async function loadMetadata(address: Address, uri: string): Promise<Response<CollectionMetadata>> {
  const data = {
    likes: 68,
    quantity: 152,
    quantitySold: 150,
  };
  return fakeResponse(data);
}
