import { Contract } from 'alchemy-sdk';
import { Address } from 'wagmi';
import { getAccount } from 'wagmi/actions';

import { alchemy, stores, wagmiClient } from 'core';
import { config } from 'shared/helpers';

import { handleError } from '../../helpers';
import { Response } from '../../types';
import { userCollectionAbi, collectionFactoryAbi, metalampAbi } from '../abi';
import { sendTransaction } from '../helpers/sendTransaction';
import { AbiItem } from '../types';

type CreateArgs = {
  name: string;
  symbol: string;
  contractUri: string;
  baseUri?: string;
};

export async function create({ name, symbol, contractUri, baseUri = '' }: CreateArgs): Promise<Response<string>> {
  return sendTransaction({
    address: config.collectionFactoryAddress,
    abi: collectionFactoryAbi as AbiItem[],
    functionName: 'createToken',
    args: [name, symbol, baseUri, contractUri],
  });
}

export async function setApproval(collectionAddress: Address, isApproved: boolean): Promise<Response<string>> {
  const isDefaultCollection = collectionAddress === config.defaultCollectionAddress;
  const abi = (isDefaultCollection ? metalampAbi : userCollectionAbi) as AbiItem[];
  const operatorAddress = config.marketAddress;

  return sendTransaction({
    abi,
    address: collectionAddress,
    functionName: 'setApprovalForAll',
    args: [operatorAddress, isApproved],
  });
}

export async function checkApproval(collectionAddress: Address) {
  try {
    const isDefaultCollection = collectionAddress === config.defaultCollectionAddress;
    const abi = (isDefaultCollection ? metalampAbi : userCollectionAbi) as AbiItem[];
    const { address } = getAccount();

    const signer = await wagmiClient.connector?.getSigner();
    const contract = new Contract(collectionAddress, abi, signer);
    const isApprovedForAll = (await contract.functions.isApprovedForAll(address, config.marketAddress)) as boolean[];

    return { data: isApprovedForAll[0], error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function loadUri(address: Address): Promise<Response<string>> {
  try {
    stores.globalLoaderStore.activate();

    const isDefaultCollection = address === config.defaultCollectionAddress;
    const abi = isDefaultCollection ? metalampAbi : userCollectionAbi;
    const signer = await wagmiClient.connector?.getSigner();
    const contract = new Contract(address, abi, signer);
    const [uri] = await contract.functions.contractURI();

    return { data: uri, error: null };
  } catch (error) {
    return handleError(error as Error);
  } finally {
    stores.globalLoaderStore.deactivate();
  }
}

export async function loadCreator(address: Address): Promise<Response<Address>> {
  try {
    stores.globalLoaderStore.activate();

    const { owners } = await alchemy.nft.getOwnersForContract(address);

    return { data: owners[0] as Address, error: null };
  } catch (error) {
    return handleError(error as Error);
  } finally {
    stores.globalLoaderStore.deactivate();
  }
}
