import * as R from 'ramda';
import { memo, useState } from 'react';
import { useMount } from 'react-use';

import { getMockFavoritedNft } from 'services/api/fake';
import { NftCard, SecondarySlider, SliderConfig } from 'shared/components';
import type { Nft } from 'shared/types';

import { Card } from './Card';
import S from './TrendingNfts.module.scss';
import notFoundIcon from '../../../assets/icons/not-found.svg';

const config: SliderConfig = {
  spaceBetween: 0,
  speed: 400,
  navigation: true,
  slidesPerView: 'auto',
  navigationContainerClass: S.navigationContainerClass,
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 30,
    stretch: 0,
    depth: 300,
    modifier: 0.45,
    slideShadows: false,
  },
  watchSlidesProgress: true,
  centeredSlides: true,
  breakpoints: {
    1440: {
      initialSlide: 3,
      slidesPerView: 7,
    },
  },
};

const Component = () => {
  const [cards, setCards] = useState<Nft[]>([]);

  useMount(async function loadNftCards() {
    const requestsList = R.range(1, 16).map(getMockFavoritedNft);
    const list = await Promise.all(requestsList);
    setCards(list);
  });

  return (
    <section>
      <h2 className={S.title}>Trending NFTs</h2>
      <SecondarySlider className={S.slider} config={config}>
        {cards.map((card) => (
          <SecondarySlider.Slide key={card.id} className={S.slide}>
            {({ isActive }) => (
              <Card
                avatar={card.creator?.avatar ?? undefined}
                ownerName={card.creator?.username || ''}
                imageUrl={card.image ?? notFoundIcon}
                nftName={card.name ?? "unknown"}
                hideContent={!isActive}
              />
            )}
          </SecondarySlider.Slide>
        ))}
      </SecondarySlider>
    </section>
  );
};

export const TrendingNfts = memo(Component);
TrendingNfts.displayName = 'TrendingNfts';
