import { makeAutoObservable } from 'mobx';

export class GlobalLoaderStore {
  constructor() {
    makeAutoObservable(this);
  }

  private counter = 0;

  get isActive(): boolean {
    return this.counter > 0;
  }

  activate(): void {
    this.counter += 1;
  }

  deactivate(): void {
    if (this.counter > 0) {
      this.counter -= 1;
    }
  }
}
