import { Avatar, CollapsedText } from 'shared/components';
import { toFileUrl } from 'shared/helpers';
import { Image } from 'shared/types';

import S from './Creator.module.scss';

type Props = {
  avatar?: Image;
  username?: string;
};

const Creator: React.FC<Props> = ({ avatar, username = '' }) => {
  const avatarSrc = toFileUrl(avatar);

  return (
    <div className={S.root}>
      <Avatar src={avatarSrc} title={username} size="xxs" />
      <div className={S.content}>
        <span className={S.label}>Creator</span>
        <CollapsedText className={S.name} text={username} rowLimit={2} />
      </div>
    </div>
  );
};

export { Creator };
