import cx from 'classnames';
import { useRef } from 'react';
import { Icon } from 'semantic-ui-react';

import { Button } from 'shared/components';

import S from './TextFilter.module.scss';

type Props = {
  text?: string;
  onChange(text?: string): void;
};

export const TextFilter: React.FC<Props> = ({ text, onChange }) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const handleChange = () => {
    onChange(inputRef.current?.innerText);
  };

  const handleResetIconClick = () => {
    onChange(undefined);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleChange();
      inputRef.current?.blur();
    }
  };

  return (
    <div className={S.root}>
      {text ? (
        <Button className={S.button} fill="solid" theme="bright" size="medium">
          <span className={S.query}>{text}</span>
          <Icon className={cx(S.icon, S.iconReset)} name="cancel" onClick={handleResetIconClick} />
        </Button>
      ) : (
        <div
          ref={inputRef}
          className={S.input}
          contentEditable
          data-placeholder="Enter a search term"
          onKeyDown={handleInputKeyDown}
        />
      )}
      <Icon className={S.icon} name="search" disabled={!!text} onClick={handleChange} />
    </div>
  );
};
