import { observer } from 'mobx-react-lite';
import { Dimmer, Loader as SemanticLoader } from 'semantic-ui-react';

import S from './Loader.module.scss';
import { useStore } from '../../store/useStore';

const Loader = observer(() => {
  const { isActive } = useStore();
  return (
    <Dimmer className={S.root} active={isActive}>
      <SemanticLoader className={S.loader} size="massive" />
    </Dimmer>
  );
});

export { Loader };
