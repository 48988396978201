import cx from 'classnames';
import { ControllerRenderProps } from 'react-hook-form';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { Option } from 'shared/types';

import S from './Select.module.scss';

type Props = {
  options: Option[];
  value: ControllerRenderProps['value'];
  invalid: boolean;
  className?: string;
  placeholder?: string;
  icon?: string;
  onChange: ControllerRenderProps['onChange'];
};

const Select: React.FC<Props> = ({
  options,
  value,
  invalid,
  className,
  placeholder,
  icon = 'angle down',
  onChange,
}) => {
  const handleChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    onChange(data.value);
  };

  return (
    <Dropdown
      className={cx(S.root, { [S.rootInvalid]: invalid }, className)}
      options={options}
      value={value}
      placeholder={placeholder}
      icon={icon}
      onChange={handleChange}
    />
  );
};

export { Select };
