import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { Address } from 'wagmi';

import { useStores } from 'core';
import { ProfileInfo } from 'features/profile';
import { ProfileAssets } from 'features/profileAssets';

import S from './ViewProfile.module.scss';

type Props = {
  isCurrent?: boolean;
};

export const ViewProfile: React.FC<Props> = observer(({ isCurrent }) => {
  const { profileStore } = useStores();
  const { profile, currentProfile, metadata, loadProfile, loadCurrentProfile, resetCurrentProfile, loadMetadata } =
    profileStore;

  const { currentAddress } = useParams();

  const userProfile = isCurrent ? currentProfile : profile;

  useMount(() => {
    loadProfile();
    loadMetadata();
  });

  useEffect(() => {
    if (isCurrent && currentAddress) {
      loadCurrentProfile(currentAddress as Address);
    }
    return resetCurrentProfile;
  }, [isCurrent, currentAddress, loadCurrentProfile, resetCurrentProfile]);

  return (
    <div className={S.root}>
      {userProfile && (
        <>
          <ProfileInfo profile={userProfile} metadata={metadata} editable={!isCurrent} />
          <ProfileAssets profile={userProfile} />
        </>
      )}
    </div>
  );
});
