import { OwnedNft } from 'alchemy-sdk';
import axios from 'axios';
import { BigNumber } from 'ethers';
import { Address } from 'wagmi';

import { alchemy, stores } from 'core';
import { config, isNotNull } from 'shared/helpers';
import { Nft } from 'shared/types';

import { convertAsset, convertIpfsUrl, customErrors, handleError } from '../../helpers';
import { Response } from '../../types';
import { userCollectionAbi, metalampAbi } from '../abi';
import { sendTransaction } from '../helpers/sendTransaction';
import { AbiItem } from '../types';

type MintArgs = {
  tokenUri: string;
  royalty: number;
  address: Address;
};

type BurnArgs = {
  tokenId: number;
  address: Address;
};

export async function mint({ tokenUri, royalty, address }: MintArgs): Promise<Response<string>> {
  if (!window.ethereum) {
    const error = new Error(customErrors.walletNotFound);
    return { data: null, error };
  }

  const { selectedAddress } = window.ethereum;
  const isDefaultCollection = address === config.defaultCollectionAddress;
  const abi = (isDefaultCollection ? metalampAbi : userCollectionAbi) as AbiItem[];

  return sendTransaction({
    address,
    abi,
    functionName: 'safeMint',
    args: [selectedAddress, tokenUri, royalty, selectedAddress],
  });
}

export async function burn({ tokenId, address }: BurnArgs): Promise<Response<string>> {
  if (!window.ethereum) {
    const error = new Error(customErrors.walletNotFound);
    return { data: null, error };
  }

  const isDefaultCollection = address === config.defaultCollectionAddress;
  const abi = (isDefaultCollection ? metalampAbi : userCollectionAbi) as AbiItem[];

  return sendTransaction({
    address,
    abi,
    functionName: 'burn',
    args: [tokenId],
  });
}

async function getNftMetadata(nft: OwnedNft): Promise<Nft | null> {
  try {
    const url = nft.tokenUri?.raw && convertIpfsUrl(nft.tokenUri.raw);
    if (!url) {
      return null;
    }

    const metadata = await axios.get<Nft>(url);
    return {
      ...convertAsset(metadata.data),
      id: +BigNumber.from(nft.tokenId).toString(),
    };
  } catch {
    return null;
  }
}

export async function loadCollectedNfts(addresses = [config.defaultCollectionAddress]): Promise<Response<Nft[]>> {
  try {
    stores.globalLoaderStore.activate();

    if (!window.ethereum) {
      throw new Error(customErrors.walletNotFound);
    }

    const { ownedNfts } = await alchemy.nft.getNftsForOwner(window.ethereum.selectedAddress, {
      contractAddresses: addresses,
    });
    const data = await Promise.all(ownedNfts.map(getNftMetadata));

    return { data: data.filter(isNotNull), error: null };
  } catch (error) {
    return handleError(error as Error, customErrors.nftLoadingError);
  } finally {
    stores.globalLoaderStore.deactivate();
  }
}
