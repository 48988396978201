import cx from 'classnames';
import { PropsWithChildren } from 'react';
import SliderComponent, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import S from './Carousel.module.scss';
import { config } from './helpers/config';

type Props = PropsWithChildren<Settings>;

export const Carousel = ({ children, className, ...restProps }: Props) => {
  const settings = { ...config, ...restProps };

  return (
    <SliderComponent className={cx(S.root, className)} {...settings}>
      {children}
    </SliderComponent>
  );
};
