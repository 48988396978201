import avatar from 'services/api/fake/assets/avatar.png';
import { Unit } from 'shared/types';

export default {
  name: 'Golden Skull',
  description:
    // eslint-disable-next-line max-len
    'The Wall Street Official Avatar Fight Club is launching its is an NFT Collection of over 50k Unique pieces from Wall Street...',
  price: 6.38,
  lastPrice: 5.93,
  dollarPrice: '$4,774.36',
  currency: Unit.ETH,
  user: {
    address: '0xb3e3bebeceF06E7EB0392E2613AC5c0B839BC32D' as const,
    avatar,
  },
};
