import { RegisterOptions } from 'react-hook-form';

import { FieldType } from 'shared/components';
import { ONE_MB } from 'shared/helpers';
import { ImageData } from 'shared/types';

import { config } from './config';
import { ValidationOptions } from './types';

export function getValidationRules(options: ValidationOptions, fieldType: FieldType) {
  const rules: RegisterOptions = {};

  const { required, patternType, min, max, fileSize, fileType, maxLength = config.maxLength[fieldType] } = options;

  if (required) {
    rules.required = { value: true, message: 'Field is required' };
  }

  if (patternType) {
    const value = config.pattern[patternType];

    if (value) {
      rules.pattern = { value, message: `Entered value does not match ${patternType} format` };
    }
  }

  if (min) {
    rules.min = { value: min, message: `Value must be at least ${min}` };
  }

  if (max) {
    rules.max = { value: max, message: `Value must be no more than ${max}` };
  }

  if (maxLength) {
    rules.maxLength = { value: maxLength, message: `Max length ${maxLength} characters` };
  }

  if (fieldType === 'file') {
    const { size, formats } = config.file;
    const maxSize = fileSize ?? size;

    rules.validate = {
      fileSize: (data?: ImageData) =>
        !data?.file?.size || data.file.size <= maxSize * ONE_MB || `${maxSize} MB file size exceeded`,
      fileFormat: (data?: ImageData) =>
        !fileType ||
        !data?.file?.type ||
        data.file.type.includes(fileType) ||
        `Unsupported file format. ${formats[fileType] ? `Supported formats: ${formats[fileType]}` : ''}`,
    };
  }

  return rules;
}
