import { makeAutoObservable, reaction, runInAction } from 'mobx';

import api from 'services/api';
import { toast } from 'shared/components';
import { Nft } from 'shared/types';

export class AssetInfoStore {
  constructor() {
    makeAutoObservable(this);
  }

  nft: Nft | null = null;

  resetNft = () => {
    this.nft = null;
  };

  loadNft = async (id: number | string) => {
    const response = await api.nft.loadNftDetails(id);
    if (!response.error) {
      runInAction(() => {
        this.nft = response.data;
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  burnNft = async ({ id, collection }: Nft) => {
    const response = await api.nft.burn({
      tokenId: id,
      address: collection.address,
    });

    // TODO: notifications using Websocket
    if (response.data) {
      toast.success('NFT is successfully burned!');
    } else {
      toast.error('An error occurred while burning NFT');
    }
  };
}
