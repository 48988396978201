import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

import { Button, FieldController } from 'shared/components';
import { NftId, NftOrder } from 'shared/types';

import S from './ListNftForm.module.scss';
import { fields } from '../helpers/fields';
import { useStore } from '../store/useStore';

type Props = {
  tokenId: NftId;
  onSubmit?: (data?: Partial<NftOrder>) => void;
};

const defaultValues = {
  price: '',
} as const;

export const ListNftForm: React.FC<Props> = observer(({ tokenId, onSubmit }) => {
  const { listNft } = useStore();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<Partial<NftOrder>>({ mode: 'onChange', defaultValues });

  const handleFormSubmit = handleSubmit(async (data) => {
    await listNft({ ...data, tokenId });
    onSubmit?.(data);
  });

  return (
    <Form className={S.root} noValidate onSubmit={handleFormSubmit}>
      <div className={S.container}>
        <div className={cx(S.group, S.groupTypeNumeric)}>
          {fields.numerics.map((field) => (
            <FieldController key={field.name} control={control} field={field} error={errors[field.name]} />
          ))}
        </div>
      </div>
      <Button className={S.saveButton} fill="solid" disabled={!isDirty}>
        Create listing
      </Button>
    </Form>
  );
});
