/* eslint-disable max-len */
import { NO_BREAK_SPACE } from 'shared/helpers';

import type { Props as CardProps } from './Card';

export const cards: CardProps[] = [
  {
    icon: 'wallet',
    title: `Connect Your${NO_BREAK_SPACE}Wallet`,
    message: `Once You've Set Up Your Wallet Of Choice, Connect It To Block3 by Clicking The NFT Marketplace In The Top Right${NO_BREAK_SPACE}Corner.`,
  },
  {
    icon: 'nft',
    title: `Create Your NFT${NO_BREAK_SPACE}Store`,
    message: `Click Create And Set Up Your Collection. Add Social Links, Profile Images, And A Secondary Sales${NO_BREAK_SPACE}Fee.`,
  },
  {
    icon: 'sale',
    title: `Start Selling &${NO_BREAK_SPACE}Growing`,
    message: `Choose Between Auctions, Fixed-Price Listings, And Declining-Price Listings. You Choose How You Want To Sell Your${NO_BREAK_SPACE}NFTs!`,
  },
];
