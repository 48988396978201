import cx from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import { Settings } from 'react-slick';
import { useWindowSize } from 'react-use';

import { Carousel } from 'shared/components';
import { SCREEN } from 'shared/types';

import S from './CardCarousel.module.scss';

type Props = PropsWithChildren<Settings>;

const sizes = [SCREEN.MD, SCREEN.LG, SCREEN.XXXL, SCREEN.XXXXL, Infinity];

export const CardCarousel = ({ className, ...props }: Props) => {
  const { width } = useWindowSize();
  const slidesToShow = sizes.findIndex((size) => width < size) + 1;

  return (
    <div className={cx(S.root, className)} style={{ '--slides-amount': slidesToShow } as CSSProperties}>
      <Carousel infinite={false} {...props} slidesToShow={slidesToShow} />
    </div>
  );
};
