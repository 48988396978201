import cx from 'classnames';
import { memo } from 'react';

import S from './Card.module.scss';

export type Props = {
  icon: 'wallet' | 'nft' | 'sale';
  title: string;
  message: string;
};

const Component: React.FC<Props> = ({ title, message, icon }) => (
  <div className={S.root}>
    {icon && <i className={cx(S.icon, S[`${icon}Icon`], `icon icon-${icon}`)} />}
    <div className={S.title}>{title}</div>
    <div className={S.message}>{message}</div>
  </div>
);

export const Card = memo(Component);
Card.displayName = 'Card';
