import { Unit } from 'shared/types';

import S from './Price.module.scss';

type Props = {
  value: number;
  unit: Unit;
};

const Price: React.FC<Props> = ({ value, unit }) => {
  return (
    <div className={S.root}>
      <div className={S.label}>Price</div>
      <div className={S.price}>
        <span>{value}</span>
        <span>{unit}</span>
      </div>
    </div>
  );
};

export { Price };
