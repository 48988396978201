import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { useStore } from 'features/assetInfo/store/useStore';
import { getMockNft } from 'services/api/fake';
import nft1 from 'services/api/fake/assets/nft1.png';
import nft2 from 'services/api/fake/assets/nft2.png';
import nft3 from 'services/api/fake/assets/nft3.png';
import image from 'services/api/fake/assets/nftAvatar.png';
import { convertIpfsUrl } from 'services/api/helpers';
import { Nft } from 'shared/types';

import { DetailsCard } from './DetailsCard';
import { NftDescription } from './NftDescription';
import S from './NftDetails.module.scss';
import { SalesHistory } from './SalesHistory';
import { AssetCardList } from '../components/AssetCardList';
import { AssetImage } from '../components/AssetImage';
import notFoundIcon from '../../../../assets/icons/not-found.svg';

export const NftDetails = observer(() => {
  const { id } = useParams();
  const { nft, loadNft, resetNft } = useStore();

  const [otherNfts, setOtherNfts] = useState<Nft[]>([]);

  useMount(async () => {
    const nfts = await Promise.all(
      [nft1, nft2, nft3, nft1, nft2, nft3].map((imageUrl, index) => getMockNft(index + 1, imageUrl)),
    );
    setOtherNfts(nfts);
  });

  useLayoutEffect(() => {
    if (id) loadNft(id);
    return resetNft;
  }, [id, loadNft, resetNft]);

  return (
    <div className={S.root}>
      <div className={S.mainInfo}>
        {nft && (
          <>
            <AssetImage url={nft.image ? convertIpfsUrl(nft.image) : notFoundIcon} likes={68} />
            <NftDescription />
          </>
        )}
      </div>
      <div className={S.tables}>
        <div className={S.table}>
          <SalesHistory />
        </div>
        <div className={S.table}>
          <DetailsCard
            blockchain="Ethereum"
            lastUpdateDate="3 days ago"
            wallet="0xb3e3bebeceF06E7EB0392E2613AC5c0B839BC32D"
            description="A collection of 10,000 undead NFTs minted on the Ethereum blockchain.
            Each unique Deadfella is randomly
            generated from a combination of over 400 individually.
            A collection of 10,000 undead NFTs minted on the Ethereum blockchain. Each unique Deadfella is randomly
            generated."
          />
        </div>
      </div>
      <AssetCardList
        nfts={otherNfts}
        title="More From This Collection"
        link={{ url: '/explore/collections/123/456', text: 'View Collection' }}
      />
    </div>
  );
});
