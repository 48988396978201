import { FieldProps } from 'shared/components';
import { Profile } from 'shared/types';

export type Field = Omit<FieldProps<Profile>, 'name'> & {
  name: keyof Profile;
};

type Fields = {
  basics: Field[];
  additionals: Field[];
  file: Field;
};

export const fields: Fields = {
  basics: [
    {
      name: 'username',
      title: 'Display Name',
      placeholder: 'e.g: Mark Basa',
      validationOptions: { required: true },
    },
    {
      name: 'email',
      title: 'Email',
      placeholder: 'e.g: Markbasa@gmail.com',
      validationOptions: { patternType: 'email' },
    },
    {
      name: 'bio',
      title: 'Short Bio',
      placeholder: "e.g: I'm an entreprenuer",
      type: 'textarea',
    },
  ],
  additionals: [
    {
      name: 'facebookAccount',
      title: 'Facebook',
      placeholder: 'e.g: @ Mark Basa',
    },
    {
      name: 'twitterAccount',
      title: 'Twitter',
      placeholder: 'e.g: @ Mark Basa',
    },
  ],
  file: {
    name: 'avatar',
    title: 'Profile photo',
    description: 'We recommend an image of at least 400x400. Gifs work too 🙌',
    validationOptions: { fileType: 'image' },
    type: 'file',
    fileProps: { needCropping: false },
  },
};
