import cx from 'classnames';
import * as R from 'ramda';
import { Image as SemanticImage } from 'semantic-ui-react';

import { Image } from 'shared/types';

import { CardHeader } from './CardHeader';
import S from './NftCollectionCard.module.scss';

type Props = {
  username: string;
  title: string;
  imageUrl: string;
  nftImageUrls?: string[];
  avatar?: Image;
  theme?: 'light' | 'dark';
};

const NftCollectionCard: React.FC<Props> = ({
  username,
  avatar,
  title,
  imageUrl,
  nftImageUrls = [],
  theme = 'light',
}) => {
  const imageUrls = imageUrl ? [imageUrl, ...nftImageUrls] : nftImageUrls;
  const images = R.take(4, imageUrls); // If the collection contains more than 4 images, display the first 4

  return (
    <div className={cx(S.root, S[`${theme}Theme`])}>
      <CardHeader title={title} avatar={avatar} username={username} theme={theme} />
      <div className={cx(S.images, S[`imagesAmount${images.length}`])}>
        {images.map((imageUrl) => (
          <SemanticImage key={imageUrl} className={S.image} src={imageUrl} />
        ))}
      </div>
    </div>
  );
};

export { NftCollectionCard };
