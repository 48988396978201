import * as R from 'ramda';
import { memo, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import api from 'services/api';
import { Preloader } from 'shared/components';
import type { HotCollection, Option } from 'shared/types';

import { Cell } from './Cell';
import S from './HotNftCollection.module.scss';

const options: Option[] = [
  { value: 1, text: '6 Hours' },
  { value: 2, text: '12 Hours' },
  { value: 3, text: '24 Hours' },
];
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const defaultOption = R.last(options)!;
const mockCollection = R.range(1, 13).map((id) => ({ id }));

const Component = () => {
  const [collection, setCollection] = useState<HotCollection | null>(null);

  const rootRef = useRef(null);
  const intersection = useIntersection(rootRef, {});
  const isSectionActive = Boolean(intersection?.isIntersecting);

  const loadCollection = async (value: DropdownProps['value']) => {
    const loadedCollection = await api.collection.loadHotCollection();
    if (loadedCollection !== null) {
      setCollection(loadedCollection);
    }
  };

  useEffect(() => {
    if (isSectionActive && collection === null) {
      loadCollection(defaultOption.value);
    }
  }, [isSectionActive, collection]);

  return (
    <section ref={rootRef}>
      <div className={S.header}>
        Hot Collection In{' '}
        <Dropdown
          className={S.dropdown}
          icon="angle down"
          options={options}
          defaultValue={defaultOption.value}
          onChange={(e, option) => loadCollection(option.value)}
        />
      </div>
      <div className={S.collection}>
        {collection &&
          collection.map((nft, index) => (
            <div key={nft.id} className={S.item}>
              <span className={S.itemNumber}>{index + 1}</span>
              <Cell
                floorPrice={nft.floorPrice}
                priceChange={nft.priceChange}
                price={nft.price}
                name={nft.name}
                avatar={nft.avatar}
                currency={nft.currency}
              />
            </div>
          ))}

        {!collection &&
          mockCollection.map((nft, index) => (
            <div key={nft.id} className={S.item}>
              <span className={S.itemNumber}>{index + 1}</span>
              <Preloader className={S.prelaoder} />
            </div>
          ))}
      </div>
    </section>
  );
};

export const HotNftCollection = memo(Component);
HotNftCollection.displayName = 'HotNftCollection';
