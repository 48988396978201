import { memo } from 'react';
import { Link } from 'react-router-dom';

import brainImage from 'assets/images/brain.jpg';
import girlImage from 'assets/images/girl.jpg';
import handImage from 'assets/images/hand.jpg';
import jettonsImage from 'assets/images/jettons.png';
import landspaceImage from 'assets/images/landspace.jpg';
import tickersImage from 'assets/images/ticker-lines.png';
import { Button, LightBall, Container } from 'shared/components';

import S from './Intro.module.scss';
import { Jetton } from './Jetton';

const Component = () => (
  <div className={S.root}>
    <Container>
      <div className={S.textContainer}>
        <div className={S.title}>
          Buy and Sell
          <div className={S.titleAccentText}>Digital Art and </div>
          NFTS
        </div>

        <div className={S.message}>
          The World&#39;s Largest Digital Marketplace For Crypto Collectibles And Non-Fungible Tokens
        </div>

        <div className={S.buttons}>
          <Button className={S.button} fill="solid">
            Explore
          </Button>

          <Link to="/create">
            <Button className={S.button}>Create</Button>
          </Link>
        </div>
      </div>

      <div className={S.backgroundContainer}>
        <LightBall className={S.lightBall1} />
        <LightBall className={S.lightBall2} />
        <LightBall className={S.lightBall3} color="var(--color-dark)" />
        <LightBall className={S.lightBall4} />
        <LightBall className={S.lightBall5} color="var(--color-dark)" />
        <Jetton className={S.jetton1} image={brainImage} />
        <Jetton className={S.jetton2} image={handImage} />
        <Jetton className={S.jetton3} image={landspaceImage} />
        <Jetton className={S.jetton4} image={girlImage} />
      </div>
    </Container>

    <div className={S.secondPart}>
      <img className={S.jettonsImage} src={jettonsImage} alt="" />
      <img className={S.tickers} src={tickersImage} alt="" />
      <LightBall className={S.lightBall6} />
      <LightBall className={S.lightBall7} />
    </div>
  </div>
);

export const Intro = memo(Component);
Intro.displayName = 'Intro';
