import { getRandomIndex, toFileUrl } from 'shared/helpers';
import { ProfileMetadata, Artist, HotCollectionItem, Nft, NftCollection, Unit, ServerProfile } from 'shared/types';

import avatar from './assets/avatar.png';
import collectedNft from './assets/collectedNft.png';
import createdNft1 from './assets/createdNft1.png';
import createdNft2 from './assets/createdNft2.png';
import createdNft3 from './assets/createdNft3.png';
import createdNft4 from './assets/createdNft4.png';
import favoritedNft from './assets/favoritedNft.png';
import nft1 from './assets/nft1.png';
import nft2 from './assets/nft2.png';
import nft3 from './assets/nft3.png';
import nft4 from './assets/nft4.png';
import nft5 from './assets/nft5.jpg';
import nft6 from './assets/nft6.jpg';
import nft7 from './assets/nft7.jpg';
import nftAvatar from './assets/nftAvatar.png';
import nftHand from '../../../assets/images/hand.jpg';

const coversList = [nft1, nft2, nft3, nft4, nft5, nft6, nft7, nftHand, collectedNft, favoritedNft, nftAvatar];
function getRandomNFTCardCover(): string {
  return coversList[getRandomIndex(coversList.length)];
}

export const mockProfileMetadata: ProfileMetadata = {
  likes: 96,
  liked: 247,
  createdItems: 1542,
};

export async function getMockProfile(): Promise<ServerProfile> {
  return {
    address: '0x00000000',
    username: 'John Doe',
    bio: 'A collection of beautiful Nfts where you could find a world of arts.',
    avatar: toFileUrl(await fetch(avatar).then((res) => res.blob())),
  };
}

export async function getMockNft(id: number, imageUrl: string): Promise<Nft> {
  return {
    id,
    primary_id: id,
    collection: { address: '0x00000000' },
    uri: '',
    name: 'Army WW1945',
    description: '',
    image: imageUrl,
    external_url: imageUrl,
    price: 6.38,
    creator: await getMockProfile(),
  };
}

export async function getMockCollectedNft(id: number): Promise<Nft> {
  return getMockNft(id, collectedNft);
}

export async function getMockFavoritedNft(id: number): Promise<Nft> {
  return getMockNft(id, getRandomNFTCardCover());
}

export async function getMockNftCollection(uri: string): Promise<NftCollection> {
  return {
    contract_address: '0x00000000',
    uri,
    name: 'NextFuture Abstract',
    description: '',
    image: '',
    external_link: '',
    nfts: await Promise.all(
      [createdNft1, createdNft2, createdNft3, createdNft4].map((image, index) => getMockNft(index + 1, image)),
    ),
    creator: await getMockProfile(),
  };
}

export function getMockFeaturedArtist(id: string): Artist {
  return {
    id,
    avatar,
    cover: createdNft1,
    name: 'Ralph Edwards',
    link: '/mock',
  };
}

export function getMockHotCollectionItem(id: string): HotCollectionItem {
  return {
    name: 'Trippin’ Ape Tribe',
    currency: Unit.ETH,
    price: 365.3,
    floorPrice: 0.32,
    priceChange: (() => Math.floor(Math.random() * 201) - 100)(),
    avatar,
    id,
  };
}
