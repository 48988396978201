import { observer } from 'mobx-react-lite';

import { AssetGrid, Button, CardCarousel, NftCard, NftCollectionCard } from 'shared/components';
import { isCollection, useIsTablet } from 'shared/helpers';

import S from './Results.module.scss';
import { useStore } from '../../store/useStore';
import notFoundIcon from '../../../../assets/icons/not-found.svg';

type Props = {
  assetType: 'nfts' | 'collections';
  title?: string;
  isCarousel?: boolean;
};

export const Results: React.FC<Props> = observer(({ assetType, title, isCarousel = false }) => {
  const { results, loadMore } = useStore();
  const items = results[assetType];

  const isTablet = useIsTablet();

  const handleLoadButtonClick = () => {
    loadMore();
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <section className={S.root}>
      {title && <h2 className={S.title}>{title}</h2>}
      {isCarousel ? (
        // TODO: add support for load more functionality
        <CardCarousel dots={!isTablet}>
          {items.map((item) =>
            isCollection(item) ? (
              <NftCollectionCard
                key={item.uri}
                title={item.name}
                username={item.creator?.username ?? ''}
                avatar={item.creator?.avatar}
                imageUrl={item.image}
                nftImageUrls={item.nfts?.map((nft) => nft.image ?? notFoundIcon)}
              />
            ) : (
              <NftCard
                key={item.id}
                title={item.name}
                username={item.creator?.username ?? ''}
                avatar={item.creator?.avatar}
                imageUrl={item.image}
                price={item.price}
              />
            ),
          )}
        </CardCarousel>
      ) : (
        <>
          <AssetGrid items={items} />
          <Button className={S.button} fill="solid" onClick={handleLoadButtonClick}>
            Load More
          </Button>
        </>
      )}
    </section>
  );
});
