import { makeAutoObservable, runInAction } from 'mobx';

import api from 'services/api';
import { toast } from 'shared/components';
import { config } from 'shared/helpers';
import { NewNft, Nft, NftCollection } from 'shared/types';

export class CreateNftStore {
  collections: NftCollection[] = [];

  private defaultRoyalty = 1;

  constructor() {
    makeAutoObservable(this);
  }

  createNft = async (data: Partial<NewNft>, resetData: () => void) => {
    if (!data.name || !data.description || !data.image) return;

    const { data: tokenUri, error } = await api.nft.createTokenUri(data as NewNft);

    if (!error) {
      const response = await api.nft.mint({
        tokenUri,
        royalty: data.royalty || this.defaultRoyalty,
        address: data.collection ?? config.defaultCollectionAddress,
      });

      // TODO: notifications using Websocket
      if (response.data) {
        toast.success('NFT is successfully minted!');
        resetData();
      } else {
        toast.error('An error occurred while minting NFT');
      }
    }
  };

  loadCollections = async () => {
    const response = await api.collection.loadList();

    runInAction(() => {
      this.collections = response.data ?? [];
    });
  };
}
