import { hexlify, toUtf8Bytes } from 'ethers/lib/utils';
import { SiweMessage } from 'siwe';

import { wagmiClient } from 'core';
import { config } from 'shared/helpers';
import { NonceResponse, SignInMessage, Nonce } from 'shared/types';

import { api } from '../../client';
import { customErrors } from '../../helpers/customErrors';
import { handleError } from '../../helpers/handleError';
import { Error, Response } from '../../types';

const { hostname, origin } = window.location;

function createSiweMessage(address: string, nonce: Nonce): string {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + config.authExpirationDays);
  const message = new SiweMessage({
    domain: hostname,
    address,
    uri: origin,
    version: '1',
    chainId: 1,
    nonce: nonce.nonce,
    issuedAt: nonce.issued_at,
    expirationTime: expirationDate.toISOString(),
  });

  return message.prepareMessage();
}

export async function signInWithEthereum(address: string, nonce: Nonce): Promise<Response<SignInMessage>> {
  try {
    const message = createSiweMessage(address, nonce);
    const siwe = hexlify(toUtf8Bytes(message));
    const signer = await wagmiClient.connector?.getSigner();
    const signature = await signer?.signMessage(message);
    const data = { signature, siwe };

    return { data, error: null };
  } catch (error) {
    return handleError(error as Error, customErrors.signInError);
  }
}

export async function generateNonce(address: string): Promise<Response<NonceResponse>> {
  return api.post('/api/login/nonce', { address });
}
