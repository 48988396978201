import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Accordion, AccordionTitleProps, Icon } from 'semantic-ui-react';

import { Button } from 'shared/components';

import S from './Filters.module.scss';
import { PriceFilter } from './PriceFilter';
import { StatusFilter } from './StatusFilter';
import { useStore } from '../../store/useStore';
import { Filter } from '../../types';

type FilterName = 'status' | 'price';

type Props = {
  enabledFilters: FilterName[];
};

export const Filters: React.FC<Props> = observer(({ enabledFilters }) => {
  const { filter, updateFilter } = useStore();

  const [filterItems, setFilterItems] = useState([
    {
      title: 'status',
      expanded: true,
      enabled: false,
      renderContent: (filter: Filter) => (
        <StatusFilter isSold={filter.isSold} setIsSold={(isSold) => updateFilter({ isSold })} />
      ),
    },
    {
      title: 'price',
      expanded: true,
      enabled: false,
      renderContent: (filter: Filter) => (
        <PriceFilter price={filter.price} onChange={(price) => updateFilter({ price })} />
      ),
    },
  ]);

  const handleResetButtonClick = () => {
    updateFilter({ isSold: undefined, price: undefined });
  };

  const handleAccordionTitleClick: AccordionTitleProps['onClick'] = (e, { title }) => {
    setFilterItems(filterItems.map((item) => (item.title === title ? { ...item, expanded: !item.expanded } : item)));
  };

  useEffect(() => {
    setFilterItems(
      filterItems.map((item) => ({ ...item, enabled: enabledFilters.includes(item.title as FilterName) })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledFilters]);

  return (
    <section className={S.root}>
      <header className={S.header}>
        <h1 className={S.title}>Filters</h1>
        <Button className={S.button} fill="solid" size="medium" onClick={handleResetButtonClick}>
          <i className="icon icon-basket" />
          Reset
        </Button>
      </header>
      {filterItems
        .filter((item) => item.enabled)
        .map((item) => (
          <Accordion key={item.title} className={S.filter} styled>
            <Accordion.Title
              className={S.filterTitle}
              title={item.title}
              active={item.expanded}
              onClick={handleAccordionTitleClick}
            >
              {item.title}
              <Icon name={`angle ${item.expanded ? 'down' : 'right'}`} />
            </Accordion.Title>
            <Accordion.Content className={S.content} active={item.expanded}>
              {item.renderContent(filter)}
            </Accordion.Content>
          </Accordion>
        ))}
    </section>
  );
});
