/* eslint-disable react/destructuring-assignment */
import { memo } from 'react';

import { Button, CollapsedText, Preloader } from 'shared/components';

import S from './ArtistCard.module.scss';

type ArtistProps = {
  isLoading?: false;
  link: string;
  name: string;
  cover: string;
  avatar: string;
};

type LoadingProps = {
  isLoading: true;
};

type Props = ArtistProps | LoadingProps;

const Component: React.FC<Props> = (props) => {
  if (props.isLoading) {
    return (
      <div className={S.root}>
        <Preloader className={S.preloader} />
        <div className={S.cover} />
        <div className={S.content}>
          <div className={S.avatar} />
          <CollapsedText className={S.name} text="Mock text" rowLimit={2} />
          <Button className={S.button} theme="bright" as="a" size="small">
            Follow
          </Button>
        </div>
      </div>
    );
  }

  const { name, cover, avatar } = props;

  return (
    <div className={S.root}>
      <img className={S.cover} src={cover} alt="" loading="lazy" />
      <div className={S.content}>
        <img className={S.avatar} src={avatar} alt="" loading="lazy" />
        <CollapsedText className={S.name} text={name} rowLimit={2} />
        {/* TODO: rewrite button to let it transforms to 
          real link to apply prop link to button */}
        <Button className={S.button} theme="bright" as="a" size="small">
          Follow
        </Button>
      </div>
    </div>
  );
};

export const ArtistCard = memo(Component);
ArtistCard.displayName = 'ArtistCard';
