import { memo } from 'react';

import { Marquee } from 'shared/components';

import { partners } from './data';
import S from './Partners.module.scss';

const Component = () => (
  <div className={S.root}>
    <div className={S.shade} />
    <Marquee className={S.marquee} pauseOnHover>
      {partners.map((partner) => (
        <div className={S.marqueeItem} key={partner.name}>
          <a href="/mock" rel="noreferrer noopener" target="_blank">
            <img className={S.image} src={partner.image} alt={partner.name} loading="lazy" />
          </a>
        </div>
      ))}
    </Marquee>
    <div className={S.shade} />
  </div>
);

export const Partners = memo(Component);
Partners.displayName = 'Partners';
