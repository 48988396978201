export const fields = {
  numerics: [
    {
      name: 'price',
      title: 'Fixed Price',
      placeholder: '0',
      validationOptions: { patternType: 'float' },
      type: 'number',
      label: 'ETH',
    },
  ],
} as const;
