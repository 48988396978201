export const navLinks = {
  // TODO: add links
  Marketplace: [
    { name: 'Explore', link: '#' },
    { name: 'All NFTs', link: '#' },
    { name: 'About', link: '#' },
  ],
  'My Account': [
    { name: 'Profile', link: '/profile' },
    { name: 'Favourites', link: '#' },
    { name: 'Watchlist', link: '#' },
    { name: 'My Collections', link: '#' },
    { name: 'Settings', link: '#' },
  ],
  Resources: [
    { name: 'Platform Status', link: '#' },
    { name: 'Partners', link: '#' },
    { name: 'Taxes', link: '#' },
    { name: 'Newsletter', link: '#' },
  ],
  Community: [
    { name: 'Help Center', link: '#' },
    { name: 'MetaLamp Token', link: '#' },
    { name: 'Suggest Feature', link: '#' },
    { name: 'Subscribe', link: '#' },
  ],
};

export const socialLinks = [
  { name: 'twitter', link: 'https://twitter.com/MetaLamp_Web' },
  { name: 'instagram', link: 'https://www.instagram.com/metalamp.io/' },
  { name: 'linkedin', link: 'https://www.linkedin.com/company/metalamp/' },
];
