import Cookies from 'js-cookie';
import { makeAutoObservable, runInAction } from 'mobx';

import api from 'services/api';
import { config } from 'shared/helpers';
import { Nonce } from 'shared/types';

export class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  isAuthorized = Cookies.get('isAuthorized') === 'true';

  private nonce: Nonce | null = null;

  login = async (walletAddress: string) => {
    if (this.isAuthorized) {
      await this.logout();
    }

    if (this.nonce) return;

    await this.generateNonce(walletAddress);

    if (this.nonce) {
      const signInResponse = await api.auth.signInWithEthereum(walletAddress, this.nonce);
      runInAction(() => {
        this.nonce = null;
      });
      if (signInResponse.error) return;

      const { siwe, signature } = signInResponse.data;

      if (siwe && signature) {
        runInAction(() => {
          this.isAuthorized = true;
          const expires = config.authExpirationDays;
          Cookies.set('isAuthorized', String(true), { expires });
          Cookies.set('siwe', siwe, { expires });
          Cookies.set('signature', signature, { expires });
        });
      }
    }
  };

  logout = async (): Promise<void> => {
    if (!this.isAuthorized) return;

    runInAction(() => {
      this.isAuthorized = false;
      Cookies.remove('isAuthorized');
      Cookies.remove('siwe');
      Cookies.remove('signature');
    });
  };

  private generateNonce = async (walletAddress: string) => {
    const response = await api.auth.generateNonce(walletAddress);
    runInAction(() => {
      this.nonce = response.error ? null : response.data[walletAddress.toLowerCase()];
    });
  };
}
