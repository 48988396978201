import * as R from 'ramda';
import { Address } from 'wagmi';

import { ServerNft, NewNft, Nft } from 'shared/types';

import { api } from '../../client';
import { fakeResponse, getMockFavoritedNft } from '../../fake';
import { customErrors, handleError } from '../../helpers';
import { Response, ResponseStatus } from '../../types';

type CreateTokenUriResponse = {
  status: ResponseStatus;
  token_uri: string;
};

type TokensSearchParams = {
  limit?: number;
  offset?: number;
  collection?: Address;
  creator?: Address;
  owner?: Address;
  search?: string;
  priceFrom?: number;
  priceTo?: number;
  on_sale?: boolean;
};

export async function createTokenUri<T extends CreateTokenUriResponse>({
  name,
  description,
  image,
}: NewNft): Promise<Response<string>> {
  try {
    if (image === null) {
      throw new Error(customErrors.noImageData);
    }

    const { coords, file } = image;
    const formData = new FormData();
    const metadata = {
      name,
      description,
      box: coords && [coords.x1, coords.y1, coords.x2, coords.y2],
    };
    formData.append('metadata', JSON.stringify(metadata));
    formData.append('image', file);

    const response = await api.post<T, Response<T>>('/api/nft/create', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.error) {
      return response;
    }

    return { data: response.data.token_uri, error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function loadFavoritedNfts(): Promise<Response<Nft[]>> {
  const nfts = await Promise.all(R.range(1, 13).map(getMockFavoritedNft));
  return fakeResponse(nfts);
}

export async function loadNfts<T extends { results: ServerNft[] }>(
  params?: TokensSearchParams,
): Promise<Response<Nft[]>> {
  try {
    const searchParams = new URLSearchParams();
    Object.entries(params ?? {}).forEach(([key, value]) => {
      if (value) searchParams.set(key, String(value));
    });

    const response = await api.get<T, Response<T>>(`/api/index/rest/tokens/?${searchParams.toString()}`);

    if (response.error) {
      return { data: null, error: response.error };
    }
    return { data: response.data.results, error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}

export async function loadNftDetails<T extends ServerNft>(id: number | string): Promise<Response<Nft>> {
  try {
    const response = await api.get<T, Response<T>>(`/api/index/rest/tokens/${id}/`);

    if (response.error) {
      return { data: null, error: response.error };
    }
    return { data: response.data, error: null };
  } catch (error) {
    return handleError(error as Error);
  }
}
