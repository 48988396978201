import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useAccount, useConnect } from 'wagmi';

import { ConnectModal } from './ConnectModal';
import S from './WalletCard.module.scss';
import { walletsData } from '../../helpers/constants';
import { WalletType } from '../../types';

type Props = {
  type: WalletType;
};

const WalletCard: React.FC<Props> = observer(({ type }) => {
  const { address } = useAccount();
  const { connect, connectors } = useConnect();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isDefaultWallet = type === 'default';
  const data = walletsData[type];

  const handleWalletCardClick = () => {
    if (isDefaultWallet || address) return;

    if (window.ethereum?.isMetaMask) {
      connect({ connector: connectors[0] });
    } else {
      setIsModalVisible(true);
    }
  };

  if (data === undefined) {
    return null;
  }

  const { icon, title } = data;

  return (
    <>
      <div
        className={cx(S.root, {
          [S.rootClickable]: !isDefaultWallet,
        })}
        onClick={handleWalletCardClick}
      >
        <img className={S.icon} src={icon} alt={title} />
        <p className={S.title}>{title}</p>
      </div>
      <ConnectModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </>
  );
});

export { WalletCard };
