import * as R from 'ramda';
import { Link } from 'react-router-dom';

import { NftCard } from 'shared/components';
import type { Nft } from 'shared/types';

import S from './Goods.module.scss';

type Props = {
  goodsList: Nft[] | null;
};

const mockGoodsList = R.range(1, 13).map((id) => ({ id, username: '' }));

export const Goods: React.FC<Props> = ({ goodsList }) => (
  <div className={S.root}>
    {goodsList &&
      goodsList.map((good) => (
        <Link key={good.id} className={S.good} to={`explore/nfts/${good.id}`}>
          <NftCard
            username={good.creator?.username ?? ''}
            avatar={good.creator?.avatar}
            price={good.price}
            imageUrl={good.image}
            title={good.name}
          />
        </Link>
      ))}
    {!goodsList &&
      mockGoodsList.map((good) => (
        <div key={good.id} className={S.good}>
          <NftCard isLoading username={good.username} />
        </div>
      ))}
  </div>
);
