import { Icon } from 'semantic-ui-react';

type Props = {
  text: string;
};

const CopyIcon: React.FC<Props> = ({ text }) => {
  const handleCopyIconClick = () => {
    navigator.clipboard.writeText(text);
  };

  return <Icon className="icon copy outline" link onClick={handleCopyIconClick} />;
};

export { CopyIcon };
