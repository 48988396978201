import { memo } from 'react';

import { NO_BREAK_SPACE } from 'shared/helpers';

import { Card } from './Card';
import { cards } from './data';
import S from './Instruction.module.scss';

const Component = () => (
  <section className={S.root}>
    <h2 className={S.title}>
      Get Started In{NO_BREAK_SPACE}3{NO_BREAK_SPACE}Steps
    </h2>
    <div className={S.cards}>
      {cards.map((card) => (
        <div key={card.title} className={S.card}>
          <Card icon={card.icon} title={card.title} message={card.message} />
        </div>
      ))}
    </div>
  </section>
);

export const Instruction = memo(Component);
Instruction.displayName = 'Instruction';
