import { memo } from 'react';

import subscribeImage from 'assets/images/subscribe-cover.png';
import { SubscriptionField } from 'shared/components';

import S from './SubscriptionSection.module.scss';

const Component = () => (
  <section className={S.root}>
    <div className={S.imageContainer}>
      <img className={S.image} src={subscribeImage} alt="" />
    </div>
    <div className={S.content}>
      <h2 className={S.title}>Subscribe to get fresh news and updates about NFTs</h2>
      <p className={S.message}>
        The World&#39;s Largest Digital Marketplace For Crypto Collectibles And Non-Fungible Tokens
      </p>
      <SubscriptionField />
    </div>
  </section>
);

export const SubscriptionSection = memo(Component);
SubscriptionSection.displayName = 'SubscriptionSection';
