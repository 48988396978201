import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { FormInput, LightBall } from 'shared/components';
import { useIsTablet } from 'shared/helpers';

import S from './Footer.module.scss';
import { navLinks, socialLinks } from './helpers/menuItems';
import { NavMenu } from './NavMenu';

const Footer = () => {
  const { pathname } = useLocation();
  const isTablet = useIsTablet();
  const isRootPage = pathname === '/';

  const handleSubscriptionInputSubmit = () => {
    // TODO: implement logic
  };

  return (
    <footer className={cx(S.root, { [S.largeSize]: isRootPage })}>
      <div className={S.layout}>
        <div className={S.container}>
          <p className={S.logo}>
            Meta<span>Lamp</span>
          </p>
          <div className={S.subscription}>
            <label htmlFor="subscriptionEmail" className={S.label}>
              Enter your email to get notified by MetaLamp for latest updates.
            </label>
            <div className={S.socials}>
              {socialLinks.map((social) => (
                <Link key={social.name} className={S.link} to={social.link} target="_blank" rel="noopener noreferrer">
                  <i className={`icon icon-social-${social.name}`} />
                </Link>
              ))}
            </div>
            <FormInput
              className={S.input}
              placeholder="Email Address"
              onSubmit={handleSubscriptionInputSubmit}
              inputId="subscriptionEmail"
              icon="arrow right"
              iconClassName={S.inputIcon}
            />
          </div>
          <NavMenu navItems={navLinks} vertical={isTablet} />
        </div>
        <div className={S.termsAndPolicy}>
          {/* TODO: add links */}
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <Link className={S.link} to="#">
            Terms
          </Link>
          <Link className={S.link} to="#">
            Privacy Policy
          </Link>
        </div>
        <p className={S.copyright}>
          <span>© MetaLamp</span>
          <span>@ All Rights Reserved</span>
        </p>
      </div>
      <LightBall className={S.lightBall1} />
      <LightBall className={S.lightBall2} color="#ffffff" />
    </footer>
  );
};

export { Footer };
