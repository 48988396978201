import { FieldProps } from 'shared/components';
import { NewNftCollection } from 'shared/types';

export type Field = Omit<FieldProps<NewNftCollection>, 'name'> & {
  name: keyof Omit<NewNftCollection, 'creator'>;
};

type Fields = {
  basics: Field[];
  file: Field;
};

export const fields: Fields = {
  basics: [
    {
      name: 'name',
      title: 'Title',
      placeholder: 'e.g: Crypto Hunks',
      validationOptions: { required: true },
    },
    {
      name: 'symbol',
      title: 'Symbol',
      placeholder: 'e.g: MYT',
      validationOptions: { required: true, patternType: 'symbol' },
      info: 'Must be 6 characters or less and contain only uppercase letters.',
    },
    {
      name: 'description',
      title: 'Description',
      placeholder: 'e.g: This is a very limited item',
      type: 'textarea',
      validationOptions: { required: true },
    },
  ],
  file: {
    name: 'image',
    title: 'Upload cover',
    description: 'Drag or choose your file to upload',
    label: 'Upload New Photo',
    validationOptions: { required: true, fileType: 'image' },
    type: 'file',
  },
};
