import { Button } from 'shared/components';

import S from './StatusFilter.module.scss';

type Props = {
  isSold?: boolean;
  setIsSold(isSold: boolean): void;
};

export const StatusFilter: React.FC<Props> = ({ isSold, setIsSold }) => {
  return (
    <div className={S.root}>
      <Button fill="solid" size="medium" theme={isSold === false ? 'bright' : 'dark'} onClick={() => setIsSold(false)}>
        Buy Now
      </Button>
      <Button fill="solid" size="medium" theme={isSold === true ? 'bright' : 'dark'} onClick={() => setIsSold(true)}>
        Sold
      </Button>
    </div>
  );
};
