import { makeAutoObservable, reaction, runInAction } from 'mobx';

import api from 'services/api';
import { Nft, NftCollection } from 'shared/types';

import { Filter, FilterResults } from '../types';

type AssetType = 'nfts' | 'collections' | 'all';

export class FilterAssetsStore {
  constructor() {
    makeAutoObservable(this);
    reaction(() => [this.filter, this.assetType, this.pagination.offset], this.loadAssets);
  }

  filter: Filter = {};

  private assetType: AssetType | null = null;

  private isLoaded = false;

  private filteredNfts: Nft[] = [];

  private filteredCollections: NftCollection[] = [];

  private pagination = {
    limit: 6,
    offset: 0,
  };

  get results(): FilterResults {
    return {
      nfts: this.filteredNfts,
      collections: this.filteredCollections,
    };
  }

  get noResults() {
    return this.isLoaded && this.filteredNfts.length === 0 && this.filteredCollections.length === 0;
  }

  setup = (assetType: AssetType) => {
    this.assetType = assetType;
  };

  cleanup = () => {
    this.filter = {};
    this.assetType = null;
    this.isLoaded = false;
    this.filteredNfts = [];
    this.filteredCollections = [];
    this.pagination.offset = 0;
  };

  updateFilter = (filter: Filter) => {
    runInAction(() => {
      this.filteredNfts = [];
      this.filteredCollections = [];
      this.pagination.offset = 0;
      this.filter = { ...this.filter, ...filter };
    });
  };

  loadMore = () => {
    this.pagination.offset += this.pagination.limit;
    // TODO: test for other assetTypes
  };

  private loadAssets = async () => {
    if (this.assetType === 'all') {
      await Promise.all([this.loadFilteredNfts(), this.loadFilteredCollections()]);
    } else if (this.assetType === 'nfts') {
      await this.loadFilteredNfts();
    } else if (this.assetType === 'collections') {
      await this.loadFilteredCollections();
    }

    if (!this.isLoaded) {
      this.isLoaded = true;
    }
  };

  private loadFilteredNfts = async () => {
    const { limit, offset } = this.pagination;
    const { price, text: search } = this.filter;
    const { priceFrom, priceTo } =
      typeof price === 'number' ? { priceFrom: 0, priceTo: price } : { priceFrom: price?.from, priceTo: price?.to };

    const response = await api.nft.loadNfts({ limit, offset, search, priceFrom, priceTo });
    if (!response.error) {
      runInAction(() => {
        this.filteredNfts = [
          ...this.filteredNfts,
          ...response.data.filter((nft) => !this.filteredNfts.some((sNft) => sNft.id === nft.id)),
        ];
      });
    }
  };

  private loadFilteredCollections = async () => {
    // TODO: replace with working collections api

    runInAction(() => {
      this.filteredCollections = [];
    });

    // const response = await api.collection.loadList(true);
    // runInAction(() => {
    //   this.filteredCollections = response.data ?? [];
    // });
  };
}
