import { ReactComponent as EthIcon } from 'assets/icons/eth.svg';
import { truncate } from 'shared/helpers';

import S from './DetailsCard.module.scss';
import { DescriptionCard } from '../DescriptionCard';

type Props = {
  description: string;
  blockchain: string;
  wallet: string;
  lastUpdateDate: string;
};

export const DetailsCard = (props: Props) => {
  const { blockchain, wallet, lastUpdateDate, description } = props;

  return (
    <DescriptionCard title="Details">
      <div className={S.root}>
        <div className={S.text}>{description}</div>
        <div className={S.info}>
          <div className={S.row}>
            <div className={S.left}>Blockchain</div>
            <div className={S.right}>
              <div className={S.inline}>
                <EthIcon />
              </div>
              <div className={S.inline}>{blockchain}</div>
            </div>
          </div>
          <div className={S.row}>
            <div className={S.left}>Contract Address</div>
            <div className={S.right}>{truncate(wallet)}</div>
          </div>
          <div className={S.row}>
            <div className={S.left}>Last updated</div>
            <div className={S.right}>{lastUpdateDate}</div>
          </div>
        </div>
      </div>
    </DescriptionCard>
  );
};
