import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { Form } from 'semantic-ui-react';
import { useAccount } from 'wagmi';

import { Button, FieldController } from 'shared/components';
import { toFileUrl, truncate } from 'shared/helpers';
import { NewNft, Option, Profile } from 'shared/types';

import S from './CreateNftForm.module.scss';
import { PreviewItem } from './PreviewItem';
import { fields } from '../../helpers/fields';
import { useStore } from '../../store/useStore';

const defaultValues = {
  name: '',
  description: '',
  price: '',
  royalty: '',
  collection: null,
  image: null,
} as const;

type Props = {
  profile: Profile | null;
};

type Options = Record<(typeof fields.lists)[number]['name'], Option[]>;

export const CreateNftForm: React.FC<Props> = observer(({ profile }) => {
  const { collections, loadCollections, createNft } = useStore();
  const { address } = useAccount();
  const { avatar, username = address ? truncate(address) : '' } = profile ?? {};

  const {
    control,
    formState: { errors, isDirty },
    watch,
    reset,
    handleSubmit,
  } = useForm<Partial<NewNft>>({ mode: 'onChange', defaultValues });
  const [name, price, image] = watch(['name', 'price', 'image']);
  const imageUrl = toFileUrl(image);

  const uploadField = fields.file;

  const options: Options = {
    collection: collections.map((collection) => ({
      value: collection.contract_address,
      text: collection.name,
    })),
  };

  useMount(loadCollections);

  return (
    <Form className={S.root} noValidate onSubmit={handleSubmit((data) => createNft(data, reset))}>
      <PreviewItem
        username={username}
        avatar={avatar}
        imageUrl={imageUrl}
        title={name ?? ''}
        price={price || undefined}
      />
      <div className={S.container}>
        <div className={cx(S.group, S.groupTypeUpload)}>
          <FieldController control={control} field={uploadField} error={errors[uploadField.name]} />
        </div>
        <div className={S.group}>
          {fields.basics.map((field) => (
            <FieldController key={field.name} control={control} field={field} error={errors[field.name]} />
          ))}
        </div>
        {/* <div className={cx(S.group, S.groupTypeNumeric)}>
          {fields.numerics.map((field) => (
            <FieldController key={field.name} control={control} field={field} error={errors[field.name]} />
          ))}
        </div> */}
        <div className={S.group}>
          {fields.lists.map((field) => (
            <FieldController
              key={field.name}
              control={control}
              field={field}
              error={errors[field.name]}
              options={options[field.name]}
            />
          ))}
        </div>
      </div>
      <Button className={S.saveButton} fill="solid" disabled={!isDirty}>
        Create
      </Button>
    </Form>
  );
});
