import { Navigate } from 'react-router-dom';

import { CollectionDetails } from 'modules/CollectionDetails';
import { ConnectWallet } from 'modules/ConnectWallet';
import { CreateItem } from 'modules/CreateItem';
import { CreateNft } from 'modules/CreateNft';
import { CreateNftCollection } from 'modules/CreateNftCollection';
import { EditProfile } from 'modules/EditProfile';
import { ExploreAssets } from 'modules/ExploreAssets';
import { Layout } from 'modules/Layout';
import { Main } from 'modules/Main';
import { NftDetails } from 'modules/NftDetails';
import { Search } from 'modules/Search';
import { ViewProfile } from 'modules/ViewProfile';

import { PrivateRoute } from './PrivateRoute';

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <Layout routes={routes} />;
};

const routes = [
  {
    path: '/',
    element: <App />,
    metadata: { name: 'Home', title: 'Home' },
    children: [
      {
        index: true,
        element: <Main />,
      },
      {
        path: 'explore',
        metadata: { name: 'Explore', title: 'Explore', isClickable: false },
        children: [
          {
            index: true,
            element: <Navigate to="/explore/nfts" replace />,
          },
          {
            path: 'nfts',
            element: <ExploreAssets assetType="nfts" />,
            metadata: { name: 'NFTs', title: 'Explore NFTs' },
          },
          {
            path: 'nfts/:id',
            metadata: { name: 'Nft Details', title: 'Nft Details' },
            element: <NftDetails />,
          },
          {
            path: 'collections',
            element: <ExploreAssets assetType="collections" />,
            metadata: { name: 'Collections', title: 'Explore Collections' },
          },
          {
            path: 'collections/:address/:uri',
            metadata: { name: 'Collection Details', title: 'Collection Details' },
            element: <CollectionDetails />,
          },
        ],
      },
      {
        path: 'stats',
        element: <p>Collection Stats</p>,
        metadata: { name: 'Stats', title: 'Collection Stats' },
      },
      {
        path: 'create',
        metadata: { name: 'Create', title: 'Create Item' },
        children: [
          {
            index: true,
            element: <PrivateRoute component={<CreateItem />} />,
          },
          {
            path: 'nft',
            element: <PrivateRoute component={<CreateNft />} />,
            metadata: { name: 'Single NFT', title: 'Create Single NFT' },
          },
          {
            path: 'collection',
            element: <PrivateRoute component={<CreateNftCollection />} />,
            metadata: { name: 'Collection', title: 'Create Collection' },
          },
        ],
      },
      {
        path: 'connect-wallet',
        element: <ConnectWallet />,
        metadata: { name: 'Connect your wallet', title: 'Connect Your Wallet' },
      },
      {
        path: 'profile',
        metadata: { name: 'My Profile', title: 'My Profile' },
        children: [
          {
            index: true,
            element: <PrivateRoute component={<ViewProfile />} />,
          },
          {
            path: 'edit',
            element: <PrivateRoute component={<EditProfile />} />,
            metadata: { name: 'Edit Profile', title: 'Edit Profile' },
          },
        ],
      },
      {
        path: 'profile/:currentAddress',
        element: <PrivateRoute component={<ViewProfile isCurrent />} />,
        metadata: { name: 'Profile', title: 'Profile' },
      },
      {
        path: 'search',
        element: <Search />,
        metadata: { name: 'Search', title: 'Search' },
      },
    ],
  },
  {
    path: '*',
    element: <p>Page Not Found</p>,
  },
];

export { routes };
