import { makeAutoObservable, runInAction } from 'mobx';
import { Address } from 'wagmi';
import { getAccount } from 'wagmi/actions';

import api from 'services/api';
import { ServerProfile, ProfileMetadata, Profile } from 'shared/types';

export class ProfileStore {
  constructor() {
    makeAutoObservable(this);
  }

  profile: ServerProfile | null = null;

  currentProfile: ServerProfile | null = null;

  metadata: ProfileMetadata | null = null;

  currentMetadata: ProfileMetadata | null = null;

  resetProfile = () => {
    this.profile = null;
  };

  resetCurrentProfile = () => {
    this.currentProfile = null;
  };

  loadProfile = async () => {
    const { address } = getAccount();
    const profile = await api.profile.load(address);
    if (!profile.error) {
      runInAction(() => {
        this.profile = profile.data;
      });
    }
  };

  loadCurrentProfile = async (address: Address) => {
    const profile = await api.profile.load(address);
    if (!profile.error) {
      runInAction(() => {
        this.currentProfile = profile.data;
      });
    }
  };

  loadMetadata = async () => {
    const metadata = await api.profile.loadMetadata();
    if (!metadata.error) {
      runInAction(() => {
        this.metadata = metadata.data;
      });
    }
  };

  updateProfile = async (data: Profile, onUpdate: () => void) => {
    // TODO: check if the data has changed

    const { address } = getAccount();
    const response = await api.profile.update(data, address);
    if (!response.error) {
      this.loadProfile();
      onUpdate();
    }
  };
}
