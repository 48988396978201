import cx from 'classnames';

import S from './Container.module.scss';

type Props = {
  className?: string;
};

export const Container = ({ children, className }: React.PropsWithChildren<Props>) => (
  <div className={cx(S.container, className)}>{children}</div>
);
