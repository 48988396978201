import { NftCard } from 'shared/components';
import { Image } from 'shared/types';

import S from './PreviewItem.module.scss';

type Props = {
  username: string;
  avatar?: Image;
  imageUrl?: string;
  title?: string;
  price?: number;
};

const PreviewItem: React.FC<Props> = ({ username, avatar, imageUrl, title, price }) => {
  return (
    <div className={S.root}>
      <h3 className={S.title}>Preview Item</h3>
      <p className={S.description}>Your NFT will look like this</p>
      <NftCard username={username} avatar={avatar} imageUrl={imageUrl} title={title} price={price} />
    </div>
  );
};

export { PreviewItem };
