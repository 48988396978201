import { Button } from 'shared/components';

import S from './NoAssets.module.scss';

type Props = {
  assetType: string;
};

const NoAssets: React.FC<Props> = ({ assetType }) => {
  return (
    <section className={S.root}>
      <h3 className={S.title}>You don&rsquo;t have any {assetType} items yet</h3>
      <div className={S.buttonsGroup}>
        <Button fill="solid">Explore</Button>
        <Button>Create</Button>
      </div>
    </section>
  );
};

export { NoAssets };
