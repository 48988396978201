import cx from 'classnames';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';

import { getValidationRules } from 'services/formValidation';
import { InfoIcon } from 'shared/components';
import { Option } from 'shared/types';

import { Field } from './Field';
import S from './FieldController.module.scss';
import { FieldProps } from './types';

type Props<T extends FieldValues> = {
  control: Control<Partial<T>>;
  field: FieldProps<T>;
  error?: { message?: FieldError['message'] };
  className?: string;
  fieldClassName?: string;
  options?: Option[];
};

const FieldController = <T extends FieldValues>({
  control,
  field,
  error,
  className,
  fieldClassName,
  options,
  children,
}: React.PropsWithChildren<Props<T>>) => {
  const { name, title, description, info, validationOptions = {}, type = 'text' } = field;
  const rules = getValidationRules(validationOptions, type);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => {
        const FieldElement = (
          <Field
            className={fieldClassName}
            value={value}
            invalid={Boolean(error)}
            field={field}
            options={options}
            onChange={onChange}
          />
        );

        return (
          <label className={cx(S.root, className)}>
            <div>
              <h3 className={cx(S.title, { [S.titleRequired]: validationOptions?.required })}>
                {title}
                {info && <InfoIcon text={info} />}
              </h3>
              {description && <p className={S.description}>{description}</p>}
            </div>
            {children ? (
              <div>
                {children}
                {FieldElement}
              </div>
            ) : (
              FieldElement
            )}
            {error && <p className={S.error}>{error.message}</p>}
          </label>
        );
      }}
    />
  );
};

export { FieldController };
