import { utils } from 'ethers';
import { makeAutoObservable, runInAction } from 'mobx';
import { Address } from 'wagmi';
import { getAccount } from 'wagmi/actions';

import api from 'services/api';
import { Response } from 'services/api/types';
import { toast } from 'shared/components';
import { config } from 'shared/helpers';
import { NftId, NftOrder } from 'shared/types';

export class ListNftStore {
  private defaultTaker: Address = '0x0000000000000000000000000000000000000000';

  private defaultPrice = utils.parseUnits('0.01', 'ether').toString();

  private defaultExpirationSeconds = 604800;

  constructor() {
    makeAutoObservable(this);
  }

  listNft = async (data: Partial<NftOrder> & { tokenId: NftId }) => {
    const { collection = config.defaultCollectionAddress } = data;
    const { address } = getAccount();
    const isApprovedResponse = await api.collection.checkApproval(collection);

    let approveResponse: Response<string>;
    if (!isApprovedResponse.data && !isApprovedResponse.error) {
      approveResponse = await api.collection.setApproval(collection, true);
    } else {
      approveResponse = { data: '', error: null };
    }

    if (!approveResponse.error && address) {
      const defaultOrder = {
        maker: address,
        taker: this.defaultTaker,
        collection: config.defaultCollectionAddress,
        currency: config.defaultCurrencyAddress,
        price: this.defaultPrice,
        start: Math.floor(Date.now() / 1000),
        end: Math.floor(Date.now() / 1000) + this.defaultExpirationSeconds,
        params: '0x',
      };
      const order = {
        ...defaultOrder,
        ...data,
        price: utils.parseUnits(String(data.price), 'ether').toString(),
      };
      const { data: signature } = await api.orders.signOrder(order);

      if (signature) {
        const response = await api.orders.createOrder({ ...order, signature });
        if (response.data) {
          toast.success('NFT is successfully listed for sale');
        } else {
          toast.error('An error occurred while listing NFT');
        }
      }
    }
  };

  // eslint-disable-next-line class-methods-use-this
  cancelListing = async (orderId: number) => {
    const response = await api.orders.deleteOrder(orderId);
  };
}
