import { memo } from 'react';

import background from 'assets/images/community-background.jpg';
import { Button } from 'shared/components';

import S from './JoinCommunity.module.scss';

const Component = () => (
  <section className={S.root}>
    <img className={S.image} src={background} alt="" loading="lazy" />
    <h2 className={S.title}>Join Our Community</h2>
    <p className={S.message}>
      The World&#39;s Largest Digital Marketplace For Crypto Collectibles And{' '}
      <span className="nowrap">Non-Fungible Tokens</span>
    </p>
    <Button className={S.link} as="a" fill="solid">
      Join now
    </Button>
  </section>
);

export const JoinCommunity = memo(Component);
JoinCommunity.displayName = 'JoinCommunity';
