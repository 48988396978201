import { FileType } from 'services/formValidation';
import { FILE_FORMATS, MAX_FILE_SIZE } from 'shared/helpers';

export function getDescription(fileType?: FileType, fileSize = MAX_FILE_SIZE) {
  const fileFormats = fileType && FILE_FORMATS[fileType];
  const fileFormatsText = fileFormats ? `${fileFormats}. ` : '';
  const fileSizeText = `Max size: ${fileSize}MB`;

  return `${fileFormatsText}${fileSizeText}`;
}
