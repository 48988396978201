import cx from 'classnames';
import { memo } from 'react';

import { Avatar, CollapsedText } from 'shared/components';

import S from './Cell.module.scss';

type Props = {
  avatar: string;
  name: string;
  price: number;
  floorPrice: number;
  priceChange: number;
  currency: string;
};

const Component: React.FC<Props> = (props) => {
  const { avatar, name, price, currency, floorPrice, priceChange } = props;
  const priceChangeSign = (function IIFE() {
    if (priceChange < 0) return 'negative';
    if (priceChange > 0) return 'positive';
    return 'neutral';
  })();

  return (
    <div className={S.root}>
      <Avatar className={S.avatar} src={avatar} size="sm" isVerified />
      <div className={S.content}>
        <div className={S.header}>
          <CollapsedText className={S.name} text={name} rowLimit={1} />
          <div className={cx(S.priceChange, S[priceChangeSign])}>
            {priceChangeSign === 'neutral' ? '--' : `${priceChange}%`}
          </div>
        </div>

        <div className={S.footer}>
          <div className={S.floorPrice}>
            Floor Price:
            <span className={S.floorPriceValue}>
              {floorPrice}
              <span className={S.floorCurrency}>{currency}</span>
            </span>
          </div>
          <div className={S.price}>
            {price}
            <span className={S.priceCurrency}>{currency}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Cell = memo(Component);
Cell.displayName = 'Cell';
