import { observer } from 'mobx-react-lite';
import * as R from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useDebounce, useIntersection } from 'react-use';

import { useStores } from 'core';
import api from 'services/api';
import { getMockFavoritedNft } from 'services/api/fake';
import { awaitTimeout } from 'shared/helpers';
import type { Nft, StatisticsData } from 'shared/types';

import { CardsColumn } from './CardsColumn';
import { Content } from './Content';
import S from './Statistics.module.scss';

const SCREEN_WIDTH_TO_HIDE_SECOND_COLUMN = 1030;
const SCREEN_WIDTH_TO_HIDE_ALL_COLUMNS = 800;

const Component = () => {
  const [cards, setCards] = useState<Nft[] | null>(null);

  const { uiStore } = useStores();
  const [windowWidth, setWindowWidth] = useState(0);
  const showSecondColumn = SCREEN_WIDTH_TO_HIDE_SECOND_COLUMN < windowWidth;
  const showColumns = SCREEN_WIDTH_TO_HIDE_ALL_COLUMNS < windowWidth;

  const [statistics, setStatistics] = useState<StatisticsData | null>(null);

  const rootRef = useRef(null);
  const intersection = useIntersection(rootRef, {});
  const isSectionActive = Boolean(intersection?.isIntersecting);

  useEffect(
    function loadNftCards() {
      if (!showColumns || !isSectionActive || cards !== null) return;

      (async function IIFE() {
        await awaitTimeout();
        const requestsList = R.range(1, 7).map(getMockFavoritedNft);
        const list = await Promise.all(requestsList);
        setCards(list);
      })();
    },
    [isSectionActive, cards, showColumns],
  );

  useEffect(
    function loadStatistics() {
      if (!isSectionActive || statistics !== null) return;

      (async function IIFE() {
        const responce = await api.statistics.load();
        setStatistics(responce);
      })();
    },
    [isSectionActive, statistics],
  );

  useDebounce(
    () => {
      setWindowWidth(uiStore.windowDimensions.width);
    },
    250,
    [uiStore.windowDimensions.width],
  );

  return (
    <section className={S.root} ref={rootRef}>
      <Content statistics={statistics} />

      {showColumns && (
        <div className={S.imageGallery}>
          <div className={S.container}>
            <CardsColumn className={S.firstColumn} cards={cards} direction="up" />
            {showSecondColumn && <CardsColumn className={S.secondColumn} cards={cards} direction="down" />}
          </div>
        </div>
      )}
    </section>
  );
};

export const Statistics = observer(Component);
Statistics.displayName = 'Statistics';
