import { matchRoutes, useLocation } from 'react-router-dom';

import { RouteType } from 'shared/types';

export function useRoutes(routes: RouteType[]) {
  const { pathname } = useLocation();
  const isRootPage = pathname === '/';
  const originalMatchedRoutes = matchRoutes(routes, pathname) ?? [];
  const matchedRoutes = originalMatchedRoutes
    .filter((match) => !match.route.index)
    .map((match, index, arr) => ({
      ...match.route,
      isActive: index === arr.length - 1,
    }));

  return {
    matchedRoutes,
    currentRoute: matchedRoutes.find((route) => route.isActive),
    isRootPage,
  };
}
