import { makeAutoObservable } from 'mobx';

import api from 'services/api';
import { toast } from 'shared/components';
import { NewNftCollection } from 'shared/types';

export class CreateNftCollectionStore {
  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  createNftCollection = async (data: Partial<NewNftCollection>, resetData: () => void) => {
    if (!data.name || !data.symbol || !data.description || !data.image) return;

    const { data: contractUri, error } = await api.collection.createTokenUri(data as NewNftCollection);

    if (!error) {
      const response = await api.collection.create({ name: data.name, symbol: data.symbol, contractUri });
      if (response.data) {
        toast.success('NFT collection is successfully created!');
        resetData();
      } else {
        toast.error('An error occurred while creating NFT collection');
      }
    }
  };
}
