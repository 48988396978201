import { Link } from 'react-router-dom';

import { convertIpfsUrl } from 'services/api/helpers';
import { NftCard, NftCollectionCard } from 'shared/components';
import { isCollection } from 'shared/helpers';
import { Asset } from 'shared/types';

import S from './AssetGrid.module.scss';
import notFoundIcon from '../../../../assets/icons/not-found.svg';

type Props = {
  items: Asset[];
};

export const AssetGrid: React.FC<Props> = ({ items }) => {
  return (
    <div className={S.root}>
      {items.map((item) =>
        isCollection(item) ? (
          <Link
            key={item.uri}
            className={S.link}
            to={`/explore/collections/${item.contract_address}/${item.uri.replace('ipfs://', '')}`}
          >
            <NftCollectionCard
              title={item.name}
              username={item.creator?.username ?? ''}
              avatar={item.creator?.avatar}
              imageUrl={item.image}
              nftImageUrls={item.nfts?.map((nft) => nft.image ?? notFoundIcon)}
            />
          </Link>
        ) : (
          <Link key={item.id} className={S.link} to={`/explore/nfts/${item.primary_id}`}>
            <NftCard
              title={item.name}
              username={(item.creator?.username || item.owner?.username) ?? ''}
              avatar={item.creator?.avatar}
              imageUrl={item.external_url ? convertIpfsUrl(item.external_url) : notFoundIcon}
              price={item.price}
            />
          </Link>
        ),
      )}
    </div>
  );
};
