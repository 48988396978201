import cx from 'classnames';
import { memo } from 'react';
import { useMount } from 'react-use';
import { useAccount } from 'wagmi';
import createWeb3Avatar from 'web3-avatar';

import { Size } from 'shared/types';

import verifyIcon from './assets/verify.svg';
import S from './Avatar.module.scss';

type Props = {
  className?: string;
  src?: string;
  title?: string;
  size?: Size;
  isVerified?: boolean;
};

let avatarCounter = 0;

const DefaultAvatar = ({ className }: Pick<Props, 'className'>) => {
  // eslint-disable-next-line no-plusplus
  const elementId = `avatar${avatarCounter++}`;
  const { address } = useAccount();

  useMount(() => {
    if (address) {
      createWeb3Avatar(`#${elementId}`, address);
    }
  });

  return <div id={elementId} className={cx(className, S.rootDefault)} />;
};

const Component: React.FC<Props> = (props) => {
  const { className, src, title = '', size = 'md', isVerified } = props;
  const classname = cx(S.root, S[`${size}Size`], className);

  if (!src) {
    return <DefaultAvatar className={classname} />;
  }

  return (
    <div className={classname}>
      <img className={S.image} src={src} alt={title} />
      {isVerified && <img className={S.verifyIcon} src={verifyIcon} alt="the user is verified" />}
    </div>
  );
};

export const Avatar = memo(Component);
Avatar.displayName = 'Avatar';
