import { memo } from 'react';
import { Icon } from 'semantic-ui-react';

import classes from './Zoom.module.scss';

type Props = {
  value: number;
  step?: number;
  min?: number;
  max?: number;
  onChange(value: number): void;
};

const ZoomComponent: React.FC<Props> = ({ value, step = 0.2, min = 1, max = 10, onChange }) => {
  const zoomInDisabled = value >= max;
  const zoomOutDisabled = value <= min;

  const zoomIn = () => {
    if (zoomInDisabled) return;
    onChange(value + step);
  };

  const zoomOut = () => {
    if (zoomOutDisabled) return;
    onChange(value - step);
  };

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} name="zoom out" disabled={zoomOutDisabled} onClick={zoomOut} />
      <Icon className={classes.icon} name="zoom in" disabled={zoomInDisabled} onClick={zoomIn} />
    </div>
  );
};

export const Zoom = memo(ZoomComponent);
Zoom.displayName = 'Zoom';
