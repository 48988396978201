import cx from 'classnames';
import { ChangeEvent, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { Input } from 'semantic-ui-react';

import { ValidationOptions } from 'services/formValidation';
import { Button, FileProps, ImageEditorModal } from 'shared/components';
import { convertBytes } from 'shared/helpers';
import { Image } from 'shared/types';

import S from './FileUploader.module.scss';
import { getDescription } from './helpers/getDescription';

type Props = {
  value: Image;
  invalid: boolean;
  className?: string;
  accept?: string;
  validationOptions?: ValidationOptions;
  fileProps?: FileProps;
  buttonText?: string;
  onChange: ControllerRenderProps['onChange'];
};

const FileUploader: React.FC<Props> = ({
  value,
  invalid,
  className,
  accept,
  validationOptions,
  fileProps,
  buttonText = 'Upload',
  onChange,
}) => {
  const [image, setImage] = useState<File | null>(null);
  const description = getDescription(validationOptions?.fileType, validationOptions?.fileSize);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.type.includes('image')) {
        setImage(file);
      } else {
        onChange(file);
      }
    }
  };

  return (
    <>
      {value ? (
        <div className={cx(S.root, S.rootUploaded, { [S.rootInvalid]: invalid }, className)}>
          <Button className={S.button} type="button" theme="bright" invalid={invalid}>
            {buttonText}
            <Input className={S.input} type="file" accept={accept} title="" onChange={handleChange} />
          </Button>
          {typeof value === 'object' && value.file && (
            <p className={S.fileInfo}>
              <span>{value.file.name}</span>
              <span>{convertBytes(value.file.size)}</span>
            </p>
          )}
        </div>
      ) : (
        <div className={cx(S.root, { [S.rootInvalid]: invalid }, className)}>
          <i className={cx('icon icon-upload', S.icon)} />
          <label className={S.label}>{description}</label>
          <Button className={S.button} type="button" theme="bright" invalid={invalid} as="div">
            {buttonText}
          </Button>
          <Input className={S.input} type="file" accept={accept} title="" onChange={handleChange} />
        </div>
      )}
      <ImageEditorModal
        isVisible={Boolean(image)}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        image={image!}
        needCropping={fileProps?.needCropping}
        onSave={onChange}
        onClose={() => setImage(null)}
      />
    </>
  );
};

export { FileUploader };
