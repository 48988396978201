import { useState } from 'react';
import { useMount } from 'react-use';
import { Address } from 'wagmi';

import api from 'services/api';
import { Toast, toast } from 'shared/components';
import { CollectionMetadata, NftCollection, ServerProfile } from 'shared/types';

import S from './CollectionDetails.module.scss';
import { Statistics } from './Statistics';
import { AssetCardList } from '../components/AssetCardList';
import { AssetDescription } from '../components/AssetDescription';
import { AssetImage } from '../components/AssetImage';

type Props = {
  address: Address;
  uri: string;
};

export const CollectionDetails: React.FC<Props> = ({ address, uri }) => {
  const [collection, setCollection] = useState<NftCollection | null>(null);
  const [metadata, setMetadata] = useState<CollectionMetadata | null>(null);
  const [creator, setCreator] = useState<ServerProfile | null>(null);

  useMount(async () => {
    const [collection, metadata, creatorAddress] = await Promise.all([
      api.collection.load({ address, uri, withNfts: true }),
      api.collection.loadMetadata(address, uri),
      api.collection.loadCreator(address),
      // TODO: load creator avatar
    ]);

    if (collection.data === null) {
      toast.error('Collection not found');
      return;
    }

    setCollection(collection.data);
    setMetadata(metadata.data);

    if (creatorAddress.data) {
      setCreator({ address: creatorAddress.data });
    }
  });

  if (collection === null) {
    return null;
  }

  return (
    <div className={S.root}>
      <div className={S.info}>
        <AssetImage url={collection.image} likes={metadata?.likes} />
        <AssetDescription name={collection.name} description={collection.description} creator={creator ?? undefined}>
          {metadata && <Statistics quantity={metadata.quantity} quantitySold={metadata.quantitySold} />}
        </AssetDescription>
      </div>
      {collection.nfts && <AssetCardList title="NFTs From This Collection" nfts={collection.nfts} />}
    </div>
  );
};
