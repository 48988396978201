import cx from 'classnames';
import { Button as SemanticButton } from 'semantic-ui-react';

import S from './Button.module.scss';

type Props = {
  className?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  fill?: 'solid' | 'outlined';
  theme?: 'dark' | 'light' | 'fullLight' | 'bright';
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  invalid?: boolean;
  as?: unknown;
  to?: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
};

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  as,
  to,
  type = 'submit',
  fill = 'outlined',
  theme = 'dark',
  disabled = false,
  invalid = false,
  size = 'large',
  onClick,
}) => {
  return (
    <SemanticButton
      className={cx(S.root, S[fill], S[`${size}Size`], S[`${theme}Theme`], { [S.invalid]: invalid }, className)}
      type={type}
      basic={fill === 'outlined'}
      disabled={disabled}
      as={as}
      to={to}
      onClick={onClick}
    >
      {children}
    </SemanticButton>
  );
};

export { Button, type Props as ButtonProps };
