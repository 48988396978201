import S from './DescriptionCard.module.scss';

type Props = {
  children: JSX.Element;
  title: string;
};

export const DescriptionCard = (props: Props) => {
  const { children, title } = props;

  return (
    <div className={S.root}>
      <div className={S.header}>{title}</div>
      <div className={S.content}>{children}</div>
    </div>
  );
};
