import React, { useRef } from 'react';
import FastMarquee from 'react-fast-marquee';
import { useIntersection } from 'react-use';

type Props = React.PropsWithChildren<{
  className?: string;
  direction?: 'left' | 'right' | 'up' | 'down';
  pauseOnHover?: boolean;
  play?: boolean;
}>;

export const Marquee = (props: Props) => {
  const { children, className, direction = 'right', pauseOnHover = false, play = true } = props;

  const rootRef = useRef(null);
  const intersection = useIntersection(rootRef, {});
  const isSectionActive = Boolean(intersection?.isIntersecting);

  return (
    <div ref={rootRef}>
      <FastMarquee
        play={isSectionActive && play}
        pauseOnHover={pauseOnHover}
        className={className}
        direction={direction}
      >
        {children}
      </FastMarquee>
    </div>
  );
};
