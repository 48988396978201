export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type Option<T = number | string> = {
  value: T;
  text: string;
};

export enum SCREEN {
  XS = 425,
  SM = 600,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1400,
  XXXL = 1800,
  XXXXL = 2200,
}
