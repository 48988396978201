import cx from 'classnames';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

import { RouteType } from 'shared/types';

import S from './BreadCrumbs.module.scss';

type Props = {
  routes: (RouteType & { isActive: boolean })[];
};

const BreadCrumbs: React.FC<Props> = ({ routes }) => {
  return (
    <Breadcrumb className={S.root}>
      {routes.map((route) => {
        if (!route.metadata) {
          return null;
        }

        const {
          isActive,
          path,
          metadata: { name, isClickable = true },
        } = route;

        return (
          <Fragment key={name}>
            <Breadcrumb.Section className={S.section}>
              {!isActive && isClickable && path ? (
                <Link className={S.link} to={path}>
                  {name}
                </Link>
              ) : (
                <span className={cx({ [S.active]: isActive })}>{name}</span>
              )}
            </Breadcrumb.Section>
          </Fragment>
        );
      })}
    </Breadcrumb>
  );
};

export { BreadCrumbs };
