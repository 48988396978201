import { Avatar } from 'shared/components';
import { truncate } from 'shared/helpers';

import S from './AvatarWallet.module.scss';

type Props = {
  userAvatar: string;
  wallet: string;
};

export const AvatarWallet = (props: Props) => {
  const { userAvatar, wallet } = props;

  return (
    <div className={S.root}>
      <Avatar className={S.avatar} src={userAvatar} />
      <div className={S.wallet}>{truncate(wallet)}</div>
    </div>
  );
};
