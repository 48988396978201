import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

import { Button, FieldController } from 'shared/components';
import { toFileUrl } from 'shared/helpers';
import { NewNftCollection } from 'shared/types';

import S from './CreateNftCollectionForm.module.scss';
import { fields } from '../../helpers/fields';
import { useStore } from '../../store/useStore';

const defaultValues = {
  name: '',
  description: '',
  symbol: '',
  image: null,
} as const;

export const CreateNftCollectionForm = observer(() => {
  const { createNftCollection } = useStore();
  const {
    control,
    formState: { errors, isDirty },
    watch,
    reset,
    handleSubmit,
  } = useForm<Partial<NewNftCollection>>({ mode: 'onChange', defaultValues });
  const image = watch('image');
  const imageUrl = toFileUrl(image);
  const uploadField = fields.file;

  return (
    <Form className={S.root} noValidate onSubmit={handleSubmit((data) => createNftCollection(data, reset))}>
      <div className={cx(S.group, S.groupTypeUpload)}>
        <FieldController
          className={cx({ [S.uploader]: Boolean(imageUrl) })}
          fieldClassName={cx({ [S.uploaderField]: Boolean(imageUrl) })}
          control={control}
          field={uploadField}
          error={errors[uploadField.name]}
        >
          {imageUrl && <img className={S.preview} src={imageUrl} alt={imageUrl} />}
        </FieldController>
      </div>
      <div className={S.group}>
        {fields.basics.map((field) => (
          <FieldController key={field.name} control={control} field={field} error={errors[field.name]} />
        ))}
      </div>
      <Button className={S.saveButton} fill="solid" disabled={!isDirty}>
        Create
      </Button>
    </Form>
  );
});
