import { useParams } from 'react-router-dom';
import { Address } from 'wagmi';

import { CollectionDetails as CollectionDetailsView } from 'features/assetInfo';

export const CollectionDetails = () => {
  const { address, uri } = useParams();

  if (!address || !uri) {
    return null;
  }

  return <CollectionDetailsView address={address as Address} uri={`ipfs://${uri}`} />;
};
