import { memo, useState } from 'react';
import { useMount } from 'react-use';

import api from 'services/api';
import { NftCollectionCard, SecondarySlider, SliderConfig } from 'shared/components';
import type { NftCollection } from 'shared/types';

import S from './ExploreCollection.module.scss';
import notFoundIcon from '../../../assets/icons/not-found.svg';

const config: SliderConfig = {
  slidesPerView: 1,
  spaceBetween: 0,
  slidesPerGroup: 1,
  navigation: true,
  breakpoints: {
    425: {
      slidesPerView: 'auto',
      spaceBetween: 34,
    },
  },
};

const Component = () => {
  const [collection, setCollection] = useState<NftCollection[]>([]);

  useMount(async function loadExploredCollectionData() {
    const requestsList = await api.collection.loadExploreCollections();

    if (requestsList.data !== null) {
      setCollection(requestsList.data);
    }
  });

  return (
    <section className={S.root}>
      <h2 className={S.title}>Explore Collection</h2>
      <SecondarySlider config={config}>
        {collection.map((slide) => (
          <SecondarySlider.Slide key={slide.uri} className={S.slide}>
            <NftCollectionCard
              theme="dark"
              username={slide.creator?.username || ''}
              title={slide.name}
              imageUrl={slide.image}
              nftImageUrls={slide.nfts?.map((nft) => nft.image ?? notFoundIcon)}
            />
          </SecondarySlider.Slide>
        ))}
      </SecondarySlider>
    </section>
  );
};

export const ExploreCollection = memo(Component);
ExploreCollection.displayName = 'ExploreCollection';
