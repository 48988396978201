/* eslint-disable @typescript-eslint/no-explicit-any */

// https://stackoverflow.com/a/21742107
export function getDeviceOperatingSystem() {
  if ((navigator as any).userAgentData?.mobile === false) {
    return 'chrome';
  }

  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'ios';
  }

  return 'default';
}
