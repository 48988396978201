/* eslint-disable no-return-assign */
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Dropdown, Menu } from 'semantic-ui-react';

import { Avatar, Button } from 'shared/components';
import { toFileUrl } from 'shared/helpers';
import { Profile, Theme } from 'shared/types';

import S from './NavMenu.module.scss';
import { ProfileInfo } from '../ProfileInfo';

type Props = {
  theme: Theme;
  isAuthorized: boolean;
  profile: Profile | null;
  connect(): void;
  disconnect(): void;
  close?(): void;
};

export const NavMenu: React.FC<Props> = ({ theme, isAuthorized, profile, connect, disconnect, close }) => {
  const { pathname } = useLocation();
  const avatarSrc = toFileUrl(profile?.avatar);

  const menuRef = useRef<HTMLDivElement>(null);
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(null);

  useEffect(() => {
    const dropdowns: NodeListOf<HTMLDivElement> | undefined = menuRef.current?.querySelectorAll('.dropdown');
    if (!dropdowns) return;

    const setOpenedIndex: EventListener = (ev) => {
      const index = (ev.currentTarget as HTMLDivElement)?.dataset?.index;
      if (index) {
        setOpenedDropdownIndex(+index);
      }
    };

    const resetOpenedIndex = () => {
      setOpenedDropdownIndex(null);
    };

    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener('mouseover', setOpenedIndex);
      dropdown.addEventListener('mouseout', resetOpenedIndex);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener('mouseover', setOpenedIndex);
        dropdown.removeEventListener('mouseout', resetOpenedIndex);
      });
    };
  }, [menuRef, isAuthorized]);

  const handleConnectButtonClick = () => {
    connect();
    close?.();
  };

  useEffect(() => {
    close?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div ref={menuRef} className={cx(S.root, S[`${theme}Theme`])}>
      <Menu className={S.menu} borderless>
        <Dropdown
          className={cx(S.menuItem, S.dropdown, {
            [S.dropdownActive]: pathname.startsWith('/explore'),
          })}
          data-index={0}
          open={openedDropdownIndex === 0}
          onClick={() => setOpenedDropdownIndex(null)}
          text="Explore"
          item
          icon={false}
        >
          <Dropdown.Menu className={S.subMenu}>
            <Dropdown.Item className={S.menuItem} as={Link} to="/explore/nfts" active={pathname === '/explore/nfts'}>
              <i className="icon icon-nfts" />
              NFTs
            </Dropdown.Item>
            <Dropdown.Item
              className={S.menuItem}
              as={Link}
              to="/explore/collections"
              active={pathname === '/explore/collections'}
            >
              <i className="icon icon-collections" />
              Collections
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className={S.menuItem} as={Link} to="/stats" active={pathname.startsWith('/stats')}>
          Stats
        </Menu.Item>
        <Dropdown
          className={cx(S.menuItem, S.dropdown, {
            [S.dropdownActive]: pathname.startsWith('/create'),
          })}
          data-index={1}
          open={openedDropdownIndex === 1}
          onClick={() => setOpenedDropdownIndex(null)}
          text="Create"
          item
          icon={false}
        >
          <Dropdown.Menu className={S.subMenu}>
            <Dropdown.Item className={S.menuItem} as={Link} to="/create/nft" active={pathname === '/create/nft'}>
              <i className="icon icon-nfts" />
              Single NFT
            </Dropdown.Item>
            <Dropdown.Item
              className={S.menuItem}
              as={Link}
              to="/create/collection"
              active={pathname === '/create/collection'}
            >
              <i className="icon icon-collections" />
              Collection
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {isAuthorized && (
          <Dropdown
            className={cx(S.menuItem, S.dropdown)}
            data-index={2}
            open={openedDropdownIndex === 2}
            onClick={() => setOpenedDropdownIndex(null)}
            item
            icon={false}
            trigger={<Avatar className={S.avatar} src={avatarSrc} title={profile?.username} size="xs" />}
          >
            <Dropdown.Menu className={S.subMenu}>
              <Dropdown.Header className={S.menuHeader}>
                <ProfileInfo username={profile?.username} />
              </Dropdown.Header>
              <Dropdown.Item className={S.menuItem} as={Link} to="/profile" active={pathname === '/profile'}>
                <i className="icon icon-profile" />
                My Profile
              </Dropdown.Item>
              <Dropdown.Item className={S.menuItem} as={Link} to="/create/nft">
                <i className="icon icon-plus" />
                Create NFT
              </Dropdown.Item>
              <Dropdown.Item className={S.menuItem} as={Link} to="/profile/edit" active={pathname === '/profile/edit'}>
                <i className="icon icon-settings" />
                Edit Profile
              </Dropdown.Item>
              <Divider />
              <Dropdown.Item className={S.menuItem} as={Link} onClick={disconnect}>
                <i className="icon icon-logout" />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Menu>
      {!isAuthorized && (
        <Button
          className={S.connectButton}
          fill="solid"
          theme={theme === 'dark' ? 'bright' : 'dark'}
          onClick={handleConnectButtonClick}
        >
          Connect Wallet
        </Button>
      )}
    </div>
  );
};
