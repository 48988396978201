import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

import S from './NavMenu.module.scss';

type NavItem = {
  name: string;
  link: string;
};

type Props = {
  navItems: Record<string, NavItem[]>;
  vertical: boolean;
};

const NavMenu: React.FC<Props> = ({ navItems, vertical }) => {
  const icon = vertical ? 'caret right' : false;

  return (
    <Menu className={S.root} borderless vertical={vertical}>
      {Object.keys(navItems).map((navTitle) => (
        <Dropdown key={navTitle} className={S.menuItem} text={navTitle} item icon={icon}>
          <Dropdown.Menu open={!vertical}>
            {navItems[navTitle].map((navItem) => (
              <Dropdown.Item key={navItem.name} className={S.navItem} as={Link}>
                <Link className={S.link} to={navItem.link}>
                  {navItem.name}
                </Link>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </Menu>
  );
};

export { NavMenu };
