import cx from 'classnames';
import { memo } from 'react';

import S from './LightBall.module.scss';

type Props = {
  color?: string;
  width?: string;
  height?: string;
  opacity?: string;
  className?: string;
};

const Component: React.FC<Props> = ({ color, width, height, opacity, className }) => (
  <div
    className={cx(S.root, className)}
    style={{
      backgroundColor: color || 'var(--color-bright)',
      width: width || '340px',
      height: height || '340px',
      opacity: opacity || '0.8',
    }}
  />
);

export const LightBall = memo(Component);
LightBall.displayName = 'LightBall';
