import { SemanticToastContainer, ToastOptions, toast as showMessage } from 'react-semantic-toasts-2';

import 'react-semantic-toasts-2/styles/react-semantic-alert.css';
import S from './Toast.module.scss';

export const Toast = () => {
  return <SemanticToastContainer className={S.root} />;
};

const show = (type: ToastOptions['type'], title: string, description?: string) =>
  showMessage({ type, title, description, time: 5000 });

const info = (title: string, description?: string) => show('info', title, description);
const success = (title: string, description?: string) => show('success', title, description);
const warning = (title: string, description?: string) => show('warning', title, description);
const error = (title: string, description?: string) => show('error', title, description);

export const toast = { info, success, warning, error };
