export const fields = {
  basics: [
    {
      name: 'name',
      title: 'Title',
      placeholder: 'e.g: Crypto Hunks',
      validationOptions: { required: true },
    },
    {
      name: 'description',
      title: 'Description',
      placeholder: 'e.g: This is a very limited item',
      type: 'textarea',
      validationOptions: { required: true },
    },
  ],
  // numerics: [
  //   {
  //     name: 'royalty',
  //     title: 'Royalties',
  //     placeholder: 'Maximum is 70%',
  //     validationOptions: { patternType: 'float', max: 70 },
  //     type: 'number',
  //     // TODO: approve the text
  //     info: `NFT royalties are payments to the original creator for every
  //     secondary sale of digital assets created by them.`,
  //   },
  // ],
  lists: [
    {
      name: 'collection',
      title: 'Collection',
      placeholder: 'Select Collection',
      description: 'This is the collection where your item will appear.',
      type: 'dropdown',
    },
  ],
  file: {
    name: 'image',
    title: 'Upload file',
    description: 'Drag or choose your file to upload',
    validationOptions: { required: true, fileType: 'image' },
    type: 'file',
  },
} as const;
