import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Tooltip } from 'shared/components';

import S from './CollapsedText.module.scss';

type Props = {
  text: string;
  rowLimit: number;
  className?: string;
  isExpandable?: boolean;
};

export const CollapsedText: React.FC<Props> = ({ text, rowLimit, className, isExpandable = false }) => {
  const [isOverflowing, setIsOverflowing] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(isOverflowing);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const { offsetWidth, scrollWidth, offsetHeight, scrollHeight } = ref.current;
    setIsOverflowing(offsetWidth < scrollWidth || offsetHeight < scrollHeight);
  }, [text]);

  const element = (
    <div
      ref={ref}
      className={cx(className, { [S.collapsed]: isCollapsed })}
      style={{ '--row-limit': rowLimit } as React.CSSProperties}
    >
      {text}
    </div>
  );

  if (!isOverflowing) {
    return element;
  }

  return isExpandable ? (
    <>
      {element}
      <div className={S.readMore} onClick={() => setIsCollapsed(!isCollapsed)}>
        Read {isCollapsed ? 'more' : 'less'}
      </div>
    </>
  ) : (
    <Tooltip text={text}>{element}</Tooltip>
  );
};
