import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'shared/components';

import S from './SubscriptionField.module.scss';

type FormInputs = {
  email: string;
};

const pattern = {
  value: /\S+@\S+\.\S+/,
  message: 'Entered value does not match email format',
};

// TODO: Finish this component
export const SubscriptionField = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <form className={S.root} onSubmit={handleSubmit(onSubmit)}>
      <input className={S.emailField} type="email" placeholder="Email Address" {...register('email', { pattern })} />
      {errors.email && (
        <span className={S.alert} role="alert">
          {errors.email.message}
        </span>
      )}
      <Button className={S.button} fill="solid" size="small">
        Join now
      </Button>
    </form>
  );
};
