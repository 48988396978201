import { observer } from 'mobx-react-lite';

import { useStores } from 'core';
import { CreateNftForm } from 'features/createNft';

const CreateNft = observer(() => {
  const { profile } = useStores().profileStore;

  return <CreateNftForm profile={profile} />;
});

export { CreateNft };
