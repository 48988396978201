import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAccount, useDisconnect, useSigner } from 'wagmi';

import { useStores } from 'core';
import { Loader } from 'services/globalLoader';
import { Header, Footer, Toast } from 'shared/components';
import { useNetworkSwitching } from 'shared/helpers';
import { RouteType } from 'shared/types';

import S from './Layout.module.scss';

type Props = {
  routes: RouteType[];
};

const Layout: React.FC<Props> = observer(({ routes }) => {
  const { authStore, profileStore } = useStores();
  const { isAuthorized, login, logout } = authStore;
  const { profile, loadProfile, resetProfile } = profileStore;

  const { address } = useAccount();
  const { isSuccess: signerIsSuccess } = useSigner();
  const { switchToDefaultNetwork } = useNetworkSwitching();

  const location = useLocation();
  const navigate = useNavigate();
  const isMainPage = location.pathname === '/';

  const { disconnect } = useDisconnect();
  const connect = () => {
    navigate('/connect-wallet', { state: { from: location } });
  };

  const autoLogin = useCallback(async () => {
    if (address && signerIsSuccess && !document.hidden && !isAuthorized) {
      await switchToDefaultNetwork();
      login(address);
    }
  }, [address, signerIsSuccess, isAuthorized, switchToDefaultNetwork, login]);

  useEffect(() => {
    if (!document.hidden) {
      autoLogin();
    } else {
      document.addEventListener('visibilitychange', autoLogin, { once: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, signerIsSuccess]);

  useEffect(() => {
    if (isAuthorized) {
      loadProfile();
    } else {
      resetProfile();
    }
  }, [isAuthorized, loadProfile, resetProfile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleDisconnect = () => {
    disconnect();
    logout();
  };

  return (
    <div className={S.root}>
      <Header
        routes={routes}
        isAuthorized={isAuthorized}
        profile={profile}
        connect={connect}
        disconnect={handleDisconnect}
      />
      {/* TODO: Get rid of global container for all pages */}
      <main className={isMainPage ? undefined : S.page}>
        <Outlet />
      </main>
      <Footer />
      <Loader />
      <Toast />
    </div>
  );
});

export { Layout };
