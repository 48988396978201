import cx from 'classnames';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { Marquee, NftCard } from 'shared/components';
import type { Nft } from 'shared/types';

import S from './CardsColumn.module.scss';

type Props = {
  cards: Nft[] | null;
  className?: string;
  direction: 'up' | 'down';
};

const mockCards = R.range(1, 4).map((id) => ({ id, username: '' }));

export const CardsColumn: React.FC<Props> = (props) => {
  const { className, cards, direction } = props;

  const hasCards = cards !== null && cards.length > 0;
  const [play, setPlay] = useState(cards !== null && cards.length > 0);
  const [debouncedPlay, setDebouncedPlay] = useState(play);

  useEffect(
    function startMarquee() {
      if (hasCards) {
        setPlay(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards],
  );

  useDebounce(
    () => {
      setDebouncedPlay(play);
    },
    150,
    [play],
  );

  const handleMouseEnter = () => {
    setPlay(false);
  };

  const handleMouseLeave = () => {
    setPlay(true);
  };

  return (
    <Marquee className={cx(S.column, className)} direction={direction} play={debouncedPlay}>
      {hasCards &&
        cards.map((card) => (
          <div className={S.card} key={card.id}>
            <div className={S.nftContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <NftCard
                username={card.creator?.username ?? ''}
                avatar={card.creator?.avatar}
                price={card.price}
                imageUrl={card.image}
                title={card.name}
              />
            </div>
          </div>
        ))}
      {!hasCards &&
        mockCards.map((card) => (
          <div className={S.card} key={card.id}>
            <div className={S.nftContainer}>
              <NftCard isLoading username={card.username} />
            </div>
          </div>
        ))}
    </Marquee>
  );
};
