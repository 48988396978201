import { Popup } from 'semantic-ui-react';

type Props = {
  text: string;
  children: React.ReactNode;
  className?: string;
};

const Tooltip: React.FC<Props> = ({ children, text, className }) => {
  return <Popup className={className} trigger={children} content={text} inverted />;
};

export { Tooltip };
