import { useEffect, useState } from 'react';

import { Input } from 'shared/components';

import S from './RangeInputs.module.scss';
import { arePricesEqual } from '../helpers/arePricesEqual';

type Props = {
  from: number;
  to: number;
  min: number;
  max: number;
  step: number;
  onChange(values: [number, number]): void;
};

export const RangeInputs: React.FC<Props> = ({ from, to, min, max, step, onChange }) => {
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);

  const handleFromInputChange = (value: number) => {
    if (value >= min && value <= to) {
      setFromValue(value);
    }
  };

  const handleToInputChange = (value: number) => {
    if (value >= from && value <= max) {
      setToValue(value);
    }
  };

  const handleInputBlur = () => {
    if (!arePricesEqual(fromValue, from) || !arePricesEqual(toValue, to)) {
      onChange([fromValue, toValue]);
    }
  };

  useEffect(() => {
    setFromValue(from);
  }, [from]);

  useEffect(() => {
    setToValue(to);
  }, [to]);

  return (
    <div className={S.root}>
      <label className={S.field}>
        <span className={S.label}>From</span>
        <Input
          className={S.input}
          value={fromValue}
          type="number"
          min={min}
          max={to}
          step={step}
          label="ETH"
          onChange={handleFromInputChange}
          onBlur={handleInputBlur}
        />
      </label>
      <label className={S.field}>
        <span className={S.label}>To</span>
        <Input
          className={S.input}
          value={toValue}
          type="number"
          min={from}
          max={max}
          step={step}
          label="ETH"
          onChange={handleToInputChange}
          onBlur={handleInputBlur}
        />
      </label>
    </div>
  );
};
