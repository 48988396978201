/* eslint-disable max-len */

const LogoDark = () => (
  <svg width="140" height="29" viewBox="0 0 140 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.4695 20.8966C43.8878 21.8257 43.2253 22.6918 42.482 23.4949C39.2663 26.975 34.6611 29.2111 29.5226 29.3371L26.5332 18.0621L36.0346 12.8184L44.4695 20.8966Z"
      fill="url(#paint0_linear_1365_1139)"
    />
    <path d="M68.4495 0.03125H65.0723V17.731H77.9347V14.9437H68.4495V0.03125Z" fill="#080F2B" />
    <path
      d="M90.4736 5.87329C89.3587 4.66077 87.8074 4.07812 86.03 4.07812C81.9903 4.07812 79.0332 6.78662 79.0332 10.9753C79.0332 15.1798 81.9903 17.9041 86.03 17.9041C87.9206 17.9041 89.5364 17.2742 90.6191 15.9829V17.7308H93.7054V4.2356H90.4575V5.87329H90.4736ZM86.4016 15.1956C84.0586 15.1956 82.2973 13.5579 82.2973 10.9753C82.2973 8.39283 84.0586 6.75513 86.4016 6.75513C88.7608 6.75513 90.5221 8.39283 90.5221 10.9753C90.5221 13.5579 88.7608 15.1956 86.4016 15.1956Z"
      fill="#080F2B"
    />
    <path
      d="M115.455 4.07812C113.257 4.07812 111.383 4.94421 110.268 6.32996C109.314 4.81824 107.618 4.07812 105.598 4.07812C103.707 4.07812 102.091 4.7395 101.057 5.95203V4.2356H97.9707V17.7308H101.219V10.8809C101.219 8.20386 102.673 6.83387 104.822 6.83387C106.793 6.83387 107.941 7.99915 107.941 10.3927V17.7308H111.172V10.8809C111.172 8.20386 112.659 6.83387 114.776 6.83387C116.747 6.83387 117.895 7.99915 117.895 10.3927V17.7308H121.142V9.99903C121.126 5.92053 118.799 4.07812 115.455 4.07812Z"
      fill="#080F2B"
    />
    <path
      d="M132.97 4.07812C131.08 4.07812 129.464 4.70801 128.381 5.99927V4.2356H125.295V22.6439H128.543V16.0931C129.658 17.3372 131.209 17.9198 132.987 17.9198C137.026 17.9198 140.016 15.1956 140.016 10.9911C139.999 6.80237 137.01 4.07812 132.97 4.07812ZM132.599 15.1956C130.272 15.1956 128.478 13.5579 128.478 10.9753C128.478 8.39283 130.272 6.75513 132.599 6.75513C134.926 6.75513 136.703 8.39283 136.703 10.9753C136.703 13.5579 134.942 15.1956 132.599 15.1956Z"
      fill="#080F2B"
    />
    <path
      d="M17.0637 0L9.98615 11.7316L2.77932 0H0V17.6997H3.2156V6.12562L9.17821 15.6684H10.681L16.6597 5.96815L16.6759 17.6997H19.8753L19.843 0H17.0637Z"
      fill="#080F2B"
    />
    <path
      d="M58.9307 5.84204C57.8157 4.62952 56.2645 4.04688 54.487 4.04688C50.4473 4.04688 47.4902 6.75537 47.4902 10.9441C47.4902 15.1486 50.4473 17.8728 54.487 17.8728C56.3776 17.8728 57.9935 17.2429 59.0761 15.9517V17.6996H62.1624V4.20435H58.9145V5.84204H58.9307ZM54.8587 15.1643C52.5318 15.1643 50.7543 13.5266 50.7543 10.9441C50.7543 8.36158 52.5156 6.72388 54.8587 6.72388C57.2178 6.72388 58.9792 8.36158 58.9792 10.9441C58.9792 13.5266 57.2178 15.1643 54.8587 15.1643Z"
      fill="#080F2B"
    />
    <path
      d="M26.0956 5.46444L27.0489 7.07064C27.3721 7.10214 27.663 7.25961 27.8569 7.52731C29.392 7.10214 30.9109 7.62179 31.9127 8.8973L33.5125 10.4248L35.6293 9.24374C35.5 8.97604 35.3222 8.6296 35.193 8.40914C33.1408 4.91329 29.4566 3.88973 26.0956 5.46444ZM22.88 15.2119C23.7849 16.7394 25.0776 17.8102 26.5965 18.3141L25.1099 12.6924C24.512 11.1806 24.819 9.66891 25.9178 8.59811C25.7724 8.29891 25.7724 7.96823 25.9178 7.66903L24.9806 6.07857C21.862 8.18868 20.9247 11.9207 22.88 15.2119Z"
      fill="#080F2B"
    />
    <path
      d="M36.6326 13.3846C36.164 15.0223 34.9197 16.4238 33.0615 17.4789C30.7992 18.7544 28.537 18.9749 26.5979 18.3135L25.1113 12.6918L31.9142 8.86523L33.5139 10.3927L26.2101 14.5027C27.5675 16.0144 29.6681 16.2979 31.7526 15.1168C33.0938 14.361 33.9179 13.3532 34.2572 12.0147C34.338 11.747 34.3703 11.4635 34.3865 11.2273L36.6326 13.3846Z"
      fill="#FFA300"
    />
    <path
      d="M43.7099 15.2741C42.498 15.2741 41.787 14.5654 41.787 13.2584V6.81787H45.5035V4.29834H41.787V1.21191H38.5391V13.3214C38.5391 16.2976 40.2842 17.8723 43.3059 17.8723C44.4693 17.8723 45.6489 17.5731 46.4246 16.959L45.5197 14.7072C45.0349 15.0851 44.3886 15.2741 43.7099 15.2741Z"
      fill="#080F2B"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1365_1139"
        x1="31.7152"
        y1="15.8955"
        x2="36.4988"
        y2="24.6917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA300" stopOpacity="0.8" />
        <stop offset="1" stopColor="#FFA300" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoDark;
