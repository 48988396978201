import cx from 'classnames';
import { useState } from 'react';
import { Form, Icon, Input as SemanticInput, SemanticICONS } from 'semantic-ui-react';

import { Theme } from 'shared/types';

import S from './FormInput.module.scss';

type Props = {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  inputId?: string;
  theme?: Theme;
  icon?: SemanticICONS;
  iconClassName?: string;
  onSubmit(query: string): void;
};

const FormInput: React.FC<Props> = ({
  className,
  defaultValue,
  placeholder,
  inputId,
  iconClassName,
  theme = 'light',
  icon = 'search',
  onSubmit,
}) => {
  const [query, setQuery] = useState(defaultValue ?? '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(query);
    setQuery('');
  };

  return (
    <Form className={cx(S.root, S[`${theme}Theme`], className)} onSubmit={handleSubmit}>
      <Form.Field
        id={inputId}
        className={S.input}
        placeholder={placeholder}
        control={SemanticInput}
        icon={<Icon className={cx(S.icon, iconClassName)} name={icon} link onClick={handleSubmit} />}
        value={query}
        onChange={handleInputChange}
      />
    </Form>
  );
};

export { FormInput };
