import { Link } from 'react-router-dom';
import { Card } from 'semantic-ui-react';

import nftCollection from './assets/nft-collection.jpg';
import singleNft from './assets/single-nft.jpg';
import S from './CreateItem.module.scss';

const CreateItem = () => {
  return (
    <section className={S.root}>
      <p className={S.text}>
        Choose <span>&ldquo;Single&rdquo;</span> to add a new item to existing collection or
        <span> &ldquo;Collection&rdquo;</span> to create new collection of items.
      </p>
      <div className={S.cards}>
        <Link to="/create/nft">
          <Card className={S.card} image={singleNft} description="Single NFT" />
        </Link>
        <Link to="/create/collection">
          <Card className={S.card} image={nftCollection} description="NFT Collection" />
        </Link>
      </div>
      <p className={S.text}>We do not own your private keys and cannot access your funds without your confirmation.</p>
    </section>
  );
};

export { CreateItem };
