import { CollapsedText, EthIcon, Preloader } from 'shared/components';
import { Image, Unit } from 'shared/types';

import { Creator } from './Creator';
import S from './NftCard.module.scss';
import { Price } from './Price';

type Props = {
  username: string;
  avatar?: Image;
  imageUrl?: string;
  title?: string;
  price?: number;
  unit?: Unit;
  isLoading?: boolean;
};

const NftCard: React.FC<Props> = ({ isLoading, username, avatar, imageUrl, unit = Unit.ETH, ...restProps }) => {
  const title = restProps.title || '-------------';
  const price = restProps.price || 0;

  return (
    <div className={S.root}>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {imageUrl && <img className={S.image} src={imageUrl} alt={title} />}
          <div className={S.content}>
            <CollapsedText className={S.title} text={title} rowLimit={1} />
            <EthIcon className={S.icon} />
            <Creator username={username} avatar={avatar} />
            <Price value={price} unit={unit} />
          </div>
        </>
      )}
    </div>
  );
};

export { NftCard };
