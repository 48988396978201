import { CroppedRect } from 'react-avatar-editor';

import { BoxCoords, BoxSize } from 'shared/types';

export function getCroppedImageCoords(imageSize: BoxSize, rect: CroppedRect): BoxCoords {
  return {
    x1: imageSize.width * rect.x,
    x2: imageSize.width * (rect.x + rect.width),
    y1: imageSize.height * rect.y,
    y2: imageSize.height * (rect.y + rect.height),
  };
}
