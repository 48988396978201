import RcSlider from 'rc-slider';
import { HandleProps } from 'rc-slider/lib/Handles/Handle';
import 'rc-slider/assets/index.css';
import { CSSProperties } from 'react';
import { Label } from 'semantic-ui-react';

import { calculateOffset } from './helpers/calculateOffset';
import S from './Slider.module.scss';

type Props = {
  value: number | number[];
  min: number;
  max: number;
  step: number;
  isRange: boolean;
  className?: string;
  onChange(value: number | number[]): void;
};

export const Slider: React.FC<Props> = ({ value, min, max, step, isRange, onChange }) => {
  const handleRender: HandleProps['render'] = ({ props }, { value }) => {
    const { style, ...restProps } = props;
    return (
      <div className={S.pointer} style={style}>
        <Label className={S.label} pointing="below">
          {value} ETH
        </Label>
        <div {...restProps} />
      </div>
    );
  };

  const { leftOffset, rightOffset } = calculateOffset(value, min, max);

  return (
    <div className={S.root} style={{ '--left-offset': leftOffset, '--right-offset': rightOffset } as CSSProperties}>
      <RcSlider
        className={S.slider}
        value={value}
        min={min}
        max={max}
        step={step}
        range={isRange}
        marks={{ [min]: `${min} ETH`, [max]: `${max} ETH` }}
        allowCross={false}
        onChange={onChange}
        handleRender={handleRender}
      />
    </div>
  );
};
