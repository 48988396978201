import { memo } from 'react';

import { Button, Preloader } from 'shared/components';
import type { StatisticsData } from 'shared/types';

import S from './Content.module.scss';

type Props = {
  statistics: StatisticsData | null;
};

const Component = ({ statistics }: Props) => (
  <div className={S.root}>
    <div className={S.container}>
      <h2 className={S.title}>
        We have the best NFT <span className={S.painted}>Digital Artists</span>
      </h2>
      <p className={S.text}>
        The World&#39;s Largest Digital Marketplace For Crypto Collectibles And Non-Fungible Tokens
      </p>
      <div className={S.stats}>
        <div>
          <div className={S.head}>
            {!statistics && <Preloader className={S.preloader} />}
            {statistics?.collectibles || 0}
          </div>
          <div className={S.body}>Collectibles</div>
        </div>
        <div>
          <div className={S.head}>
            {!statistics && <Preloader className={S.preloader} />}
            {statistics?.auctions || 0}
          </div>
          <div className={S.body}>Auctions</div>
        </div>
        <div>
          <div className={S.head}>
            {!statistics && <Preloader className={S.preloader} />}
            {statistics?.artists || 0}
          </div>
          <div className={S.body}>Artists</div>
        </div>
      </div>
      <Button className={S.button} theme="fullLight" type="button">
        Create
      </Button>
    </div>
  </div>
);

export const Content = memo(Component);
Content.displayName = 'Content';
