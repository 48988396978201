import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { Address } from 'wagmi';

import api from 'services/api';
import { config, isNotNull } from 'shared/helpers';
import { Nft, NftCollection } from 'shared/types';

import { AssetType } from '../types';

type Assets = {
  createdNfts: Nft[];
  collectedNfts: Nft[];
  favoritedNfts: Nft[];
  nftCollections: NftCollection[];
};

export class ProfileAssetsStore {
  constructor() {
    makeAutoObservable(this);
    reaction(() => [this.activeAssetTab, this.address, this.pagination.offset], this.loadAssets);
  }

  activeAssetTab: AssetType = 'collectedNfts';

  private createdNfts: Nft[] = [];

  private collectedNfts: Nft[] = [];

  private favoritedNfts: Nft[] = [];

  private nftCollections: NftCollection[] = [];

  private address: Address | undefined = undefined;

  private pagination = {
    limit: 6,
    offset: 0,
  };

  get assets(): Assets {
    return {
      createdNfts: this.createdNfts,
      collectedNfts: this.collectedNfts,
      favoritedNfts: this.favoritedNfts,
      nftCollections: this.nftCollections,
    };
  }

  setActiveAssetTab = (tab: AssetType) => {
    this.activeAssetTab = tab;
    this.pagination.offset = 0;
  };

  setup = (address?: Address) => {
    if (address) {
      this.address = address;
    }
    if (this.activeAssetTab !== 'collectedNfts') {
      this.setActiveAssetTab('collectedNfts');
    } else {
      this.loadAssets();
    }
  };

  cleanup = () => {
    runInAction(() => {
      this.createdNfts = [];
      this.collectedNfts = [];
      this.favoritedNfts = [];
      this.nftCollections = [];
      this.address = undefined;
      this.pagination.offset = 0;
    });
  };

  loadAssets = async () => {
    if (this.activeAssetTab === 'createdNfts') {
      this.loadCreatedNfts();
    } else if (this.activeAssetTab === 'collectedNfts') {
      this.loadCollectedNfts();
    } else if (this.activeAssetTab === 'favoritedNfts') {
      this.loadFavoritedNfts();
    } else if (this.activeAssetTab === 'nftCollections') {
      this.loadNftCollections();
    }
  };

  loadMore = () => {
    this.pagination.offset += this.pagination.limit;
    // TODO: test for other assetTypes
  };

  private loadCreatedNfts = async () => {
    if (this.address) {
      const creator = this.address;
      const { limit, offset } = this.pagination;
      const createdNfts = await api.nft.loadNfts({ limit, offset, creator });
      if (!createdNfts.error) {
        runInAction(() => {
          this.createdNfts = [
            ...this.createdNfts,
            ...createdNfts.data.filter((nft) => !this.createdNfts.some((sNft) => sNft.id === nft.id)),
          ];
        });
      }
    }
  };

  private loadCollectedNfts = async () => {
    if (this.address) {
      const owner = this.address;
      const { limit, offset } = this.pagination;
      const collectedNfts = await api.nft.loadNfts({ limit, offset, owner });
      if (!collectedNfts.error) {
        runInAction(() => {
          this.collectedNfts = [
            ...this.collectedNfts,
            ...collectedNfts.data.filter((nft) => !this.collectedNfts.some((sNft) => sNft.id === nft.id)),
          ];
        });
      }
    }
  };

  private loadFavoritedNfts = async () => {
    const favoritedNfts = await api.nft.loadFavoritedNfts();
    if (!favoritedNfts.error) {
      runInAction(() => {
        this.favoritedNfts = favoritedNfts.data;
      });
    }
  };

  private loadNftCollections = async () => {
    const [{ data: defaultCollection }, { data: defaultCollectionNfts }, { data: otherCollections }] =
      await Promise.all([
        api.collection.load({ address: config.defaultCollectionAddress }),
        api.nft.loadCollectedNfts(),
        api.collection.loadList(true),
      ]);

    if (defaultCollection) {
      defaultCollection.nfts = defaultCollectionNfts ?? undefined;

      // TODO: specify what to display if there is no collection name
      if (!defaultCollection.name) {
        defaultCollection.name = 'Metalamp';
      }
    }

    runInAction(() => {
      this.nftCollections = [defaultCollection, otherCollections].filter(isNotNull).flat();
    });
  };
}
