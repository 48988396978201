import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Image, Modal } from 'semantic-ui-react';

import { Button } from 'shared/components';
import { getDeviceOperatingSystem } from 'shared/helpers';

import S from './ConnectModal.module.scss';
import androidQrCode from '../../../assets/metamask-qrcode-android.png';
import iosQrCode from '../../../assets/metamask-qrcode-ios.png';

const links = {
  chrome: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  android: 'https://play.google.com/store/apps/details?id=io.metamask',
  ios: 'https://apps.apple.com/us/app/metamask/id1438144202',
  default: 'https://metamask.io/download/',
};

type Props = {
  isVisible: boolean;
  onClose(): void;
};

const ConnectModal: React.FC<Props> = observer(({ isVisible, onClose }) => {
  const device = getDeviceOperatingSystem();
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleClose = () => {
    onClose();
    setIsContentVisible(false);
  };

  return (
    <Modal className={S.root} open={isVisible} closeIcon size="tiny" onClose={handleClose}>
      {isContentVisible ? (
        <Modal.Content className={S.content}>
          <h3 className={S.header}>Get MetaMask</h3>
          <div className={S.section}>
            <h3>MetaMask for Chrome</h3>
            <p>Access your wallet right from your favorite web browser.</p>
            <Link className={S.link} to={links.chrome} target="_blank" rel="noopener noreferrer">
              Add to Chrome
            </Link>
          </div>
          <div className={S.section}>
            <h3>MetaMask for Mobile</h3>
            <p>Use the mobile wallet to explore the world of Ethereum.</p>
            <Link
              className={S.link}
              to={device === 'ios' ? links.ios : links.android}
              target="_blank"
              rel="noopener noreferrer"
            >
              Get the app
            </Link>
          </div>
        </Modal.Content>
      ) : (
        <Modal.Content className={S.content}>
          <h3 className={S.header}>Scan with MetaMask</h3>
          <Image size="medium" src={device === 'ios' ? iosQrCode : androidQrCode} />
          <Modal.Description className={S.description}>
            <p>Don&rsquo;t have MetaMask?</p>
            <Button className={S.button} onClick={() => setIsContentVisible(true)}>
              GET
            </Button>
          </Modal.Description>
        </Modal.Content>
      )}
    </Modal>
  );
});

export { ConnectModal };
