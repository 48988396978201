import { memo } from 'react';

import { Container, LightBall } from 'shared/components';

import { ExploreCollection } from './ExploreCollection';
import { FeaturedArtists } from './FeaturedArtists';
import { HotNftCollection } from './HotNftCollection';
import { Instruction } from './Instruction';
import { Intro } from './Intro';
import { JoinCommunity } from './JoinCommunity';
import S from './Main.module.scss';
import { OnSale } from './OnSale';
import { Partners } from './Partners';
import { Statistics } from './Statistics';
import { SubscriptionSection } from './SubscriptionSection';
import { TrendingNfts } from './TrendingNfts';

const Component = () => (
  <>
    <div className={S.introSection}>
      <Intro />
    </div>

    <div className={S.partnersSection}>
      <Partners />
    </div>

    <div className={S.statisticsSection}>
      <div className={S.statisticsContainer}>
        <Statistics />
        <LightBall className={S.lightBall1} />
        <LightBall className={S.lightBall2} color="#FF377F" />
      </div>
    </div>

    <Container>
      <div className={S.pageSection}>
        <Instruction />
      </div>

      <div className={S.pageSection}>
        <OnSale />
      </div>
    </Container>

    <div className={S.filledContainer}>
      <Container>
        <div className={S.pageSection}>
          <HotNftCollection />
        </div>
      </Container>
      <div className={S.trendingNftSection}>
        <TrendingNfts />
      </div>
      <Container>
        <ExploreCollection />
      </Container>
    </div>

    <Container className={S.bottonContainer}>
      <LightBall className={S.lightBall3} />
      <LightBall className={S.lightBall4} color="var(--color-dark)" />
      <LightBall className={S.lightBall5} />
      <div className={S.pageSection}>
        <FeaturedArtists />
      </div>

      <SubscriptionSection />

      <div className={S.communitySection}>
        <JoinCommunity />
      </div>
    </Container>
  </>
);

export const Main = memo(Component);
Main.displayName = 'Main';
