import { memo, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal } from 'semantic-ui-react';

import { Button } from 'shared/components';
import { toFileUrl } from 'shared/helpers';
import { ImageData } from 'shared/types';

import { config } from './helpers/config';
import { getCroppedImageCoords } from './helpers/getCoords';
import { getImageSize } from './helpers/getImageSize';
import classes from './ImageEditorModal.module.scss';
import { Zoom } from './Zoom';

type Props = {
  isVisible: boolean;
  image: File;
  needCropping?: boolean;
  onSave(image: ImageData): void;
  onClose(): void;
};

const ImageEditorModalComponent: React.FC<Props> = ({ isVisible, image, needCropping = true, onSave, onClose }) => {
  const editorRef = useRef<AvatarEditor>(null);
  const [zoom, setZoom] = useState(needCropping ? undefined : config.zoom);

  const handleSaveButtonClick = async () => {
    try {
      const editor = editorRef.current;
      editor?.getImageScaledToCanvas().toBlob(async (res) => {
        const resizedImage = res ? new File([res], image.name, { type: image.type }) : undefined;
        const rect = editor.getCroppingRect();
        const imageUrl = toFileUrl(image);
        const imageSize = imageUrl && (await getImageSize(imageUrl));
        if (!imageSize || !rect) return;

        onSave({
          file: needCropping || !resizedImage ? image : resizedImage,
          preview: resizedImage,
          coords: getCroppedImageCoords(imageSize, rect),
        });
      });
    } catch (error) {
      console.error(error); // TODO: add error handling
    } finally {
      onClose();
    }
  };

  const { size, previewWidth, border } = config;
  return (
    <Modal className={classes.root} open={isVisible} closeIcon size="tiny" onClose={onClose}>
      <Modal.Header>
        <h3>Tune your image</h3>
      </Modal.Header>
      <Modal.Content className={classes.content}>
        <AvatarEditor
          ref={editorRef}
          image={image}
          width={previewWidth}
          height={previewWidth * (size.height / size.width)}
          border={border}
          scale={zoom}
          color={[255, 255, 255, 0.6]}
        />
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        {zoom && <Zoom value={zoom} onChange={setZoom} />}
        <Button className={classes.button} onClick={handleSaveButtonClick}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ImageEditorModal = memo(ImageEditorModalComponent);
ImageEditorModal.displayName = 'ImageEditorModal';
