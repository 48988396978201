import { Settings } from 'react-slick';

export const config: Settings = {
  variableWidth: true,
  nextArrow: (
    <div>
      <i className="icon icon-arrow" />
    </div>
  ),
  prevArrow: (
    <div>
      <i className="icon icon-arrow" />
    </div>
  ),
};
