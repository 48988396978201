import cx from 'classnames';
import { ControllerRenderProps } from 'react-hook-form';
import { Checkbox as SemanticCheckbox, CheckboxProps } from 'semantic-ui-react';

import S from './Checkbox.module.scss';

type Props = {
  value: ControllerRenderProps['value'];
  invalid: boolean;
  className?: string;
  onChange: ControllerRenderProps['onChange'];
};

const Checkbox: React.FC<Props> = ({ value, invalid, className, onChange }) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    onChange(data.checked);
  };

  return (
    <SemanticCheckbox
      toggle
      className={cx(S.root, { [S.rootInvalid]: invalid }, className)}
      checked={value}
      onChange={handleChange}
    />
  );
};

export { Checkbox };
