import cx from 'classnames';
import { HTMLInputTypeAttribute } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { Input as SemanticInput, InputOnChangeData, InputProps } from 'semantic-ui-react';

import { NUMERIC_INPUT_MAX_LENGTH, TEXT_INPUT_MAX_LENGTH } from 'shared/helpers';

import S from './Input.module.scss';

type Props = Omit<InputProps, 'value' | 'onChange'> & {
  value: ControllerRenderProps['value'];
  onChange: ControllerRenderProps['onChange'];
};

const Input: React.FC<Props> = ({
  className,
  label,
  type = 'text',
  invalid = false,
  maxLength = type === 'number' ? NUMERIC_INPUT_MAX_LENGTH : TEXT_INPUT_MAX_LENGTH,
  onChange,
  ...restProps
}) => {
  if (type === 'number') {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      let inputValue = data.value;
      if (inputValue.length > maxLength) {
        inputValue = inputValue.substring(0, maxLength);
      }
      onChange(+inputValue);
    };

    const handleWheel = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.blur();
    };

    return (
      <SemanticInput
        className={cx(S.root, { [S.rootInvalid]: invalid }, className)}
        type="number"
        label={label}
        labelPosition={label ? 'right' : undefined}
        onChange={handleChange}
        onWheel={handleWheel}
        {...restProps}
      />
    );
  }
  return (
    <SemanticInput
      className={cx(S.root, { [S.rootInvalid]: invalid }, className)}
      type={type}
      label={label}
      labelPosition={label ? 'right' : undefined}
      maxLength={maxLength}
      onChange={onChange}
      {...restProps}
    />
  );
};

export { Input };
