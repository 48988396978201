import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Tab, TabProps } from 'semantic-ui-react';

import { AssetGrid, Button } from 'shared/components';
import { Option, ServerProfile } from 'shared/types';

import { NoAssets } from './NoAssets';
import S from './ProfileAssets.module.scss';
import { useStore } from '../../store/useStore';
import { AssetType } from '../../types';

const tabs: Option<AssetType>[] = [
  { value: 'collectedNfts', text: 'collected' },
  { value: 'createdNfts', text: 'created' },
  // { value: 'nftCollections', text: 'created' }, // TODO: remove?
  { value: 'favoritedNfts', text: 'favorited' },
];

type Props = {
  profile: ServerProfile | null;
};

export const ProfileAssets: React.FC<Props> = observer(({ profile }) => {
  const { activeAssetTab, assets, setup, cleanup, setActiveAssetTab, loadMore } = useStore();
  const items = assets[activeAssetTab];
  const assetType = tabs.find((tab) => tab.value === activeAssetTab)?.text;

  const handleLoadButtonClick = () => {
    loadMore();
  };

  const panes = tabs.map((tab) => ({
    menuItem: tab.text,
    render: () => (
      <Tab.Pane className={S.tab} attached={false}>
        {items.length === 0 ? (
          <NoAssets assetType={assetType ?? ''} />
        ) : (
          <>
            <AssetGrid items={items} />
            <Button className={S.button} fill="solid" onClick={handleLoadButtonClick}>
              Load More
            </Button>
          </>
        )}
      </Tab.Pane>
    ),
  }));

  const handleTabChange = (e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
    if (typeof activeIndex !== 'number') return;
    setActiveAssetTab(tabs[activeIndex].value);
  };

  useEffect(() => {
    cleanup();
    setup(profile?.address);

    return cleanup;
  }, [profile?.address, cleanup, setup]);

  return (
    <Tab
      className={S.root}
      menu={{ secondary: true, pointing: true, className: S.menu }}
      panes={panes}
      onTabChange={handleTabChange}
    />
  );
});
