import { stores } from 'core/configureStores';

import { Response } from '../types';

export async function fakeResponse<T>(data: T, disableLoader?: boolean): Promise<Response<T>> {
  const { globalLoaderStore } = stores;
  if (!disableLoader) globalLoaderStore.activate();

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data, error: null });
      globalLoaderStore.deactivate();
    }, 1000);
  });
}
