import cx from 'classnames';
import { memo } from 'react';

import { toFileUrl } from 'shared/helpers';
import type { Image } from 'shared/types';

import S from './Card.module.scss';

type Props = {
  imageUrl: string;
  nftName: string;
  ownerName: string;
  avatar?: Image;
  hideContent?: boolean;
};

const Component: React.FC<Props> = ({ avatar, imageUrl, nftName, ownerName, hideContent }) => (
  <div className={S.root}>
    <img className={S.image} src={imageUrl} alt={nftName} />
    <div className={cx(S.content, { [S.isHidden]: hideContent })}>
      <img className={S.avatar} src={toFileUrl(avatar)} alt={ownerName} />
      <div className={S.textBlock}>
        <span className={S.nftName}>{nftName}</span>
        <span className={S.ownerName}>{ownerName}</span>
      </div>
    </div>
  </div>
);

export const Card = memo(Component);
Card.displayName = 'Card';
