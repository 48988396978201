import cx from 'classnames';

import { Tooltip } from 'shared/components';

import S from './InfoIcon.module.scss';

type Props = {
  text: string;
};

const InfoIcon: React.FC<Props> = ({ text }) => {
  return (
    <Tooltip text={text}>
      <i className={cx('icon icon-info', S.icon)} />
    </Tooltip>
  );
};

export { InfoIcon };
