import { AssetInfoStore } from 'features/assetInfo/store/AssetInfoStore';
import { AuthStore } from 'features/auth';
import { BuyNftStore } from 'features/buyNft/store/BuyNftStore';
import { CreateNftStore } from 'features/createNft';
import { CreateNftCollectionStore } from 'features/createNftCollection';
import { FilterAssetsStore } from 'features/filterAssets';
import { ListNftStore } from 'features/listNft/store/ListNftStore';
import { ProfileStore } from 'features/profile';
import { ProfileAssetsStore } from 'features/profileAssets';
import { GlobalLoaderStore } from 'services/globalLoader';

import { UiStore } from './UiStore';

export class RootStore {
  uiStore: UiStore;
  authStore: AuthStore;
  globalLoaderStore: GlobalLoaderStore;
  profileStore: ProfileStore;
  profileAssetsStore: ProfileAssetsStore;
  createNftStore: CreateNftStore;
  createNftCollectionStore: CreateNftCollectionStore;
  filterAssetsStore: FilterAssetsStore;
  assetInfoStore: AssetInfoStore;
  listNftStore: ListNftStore;
  buyNftStore: BuyNftStore;

  constructor() {
    this.uiStore = new UiStore();
    this.authStore = new AuthStore();
    this.globalLoaderStore = new GlobalLoaderStore();
    this.profileStore = new ProfileStore();
    this.profileAssetsStore = new ProfileAssetsStore();
    this.createNftStore = new CreateNftStore();
    this.createNftCollectionStore = new CreateNftCollectionStore();
    this.filterAssetsStore = new FilterAssetsStore();
    this.assetInfoStore = new AssetInfoStore();
    this.listNftStore = new ListNftStore();
    this.buyNftStore = new BuyNftStore();
  }
}
