import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { Button, Slider } from 'shared/components';

import { arePricesEqual } from './helpers/arePricesEqual';
import { config } from './helpers/config';
import { isPriceRange } from './helpers/typeGuards';
import S from './PriceFilter.module.scss';
import { RangeInputs } from './RangeInputs';
import { PriceFilter as PriceFilterType } from '../../../types';

type Props = {
  price?: PriceFilterType;
  onChange(value: PriceFilterType): void;
};

export const PriceFilter: React.FC<Props> = ({ price, onChange }) => {
  const [value, setValue] = useState<PriceFilterType | undefined>(price ?? config.max);
  const isRange = isPriceRange(value);

  const handleToggleButtonClick = () => {
    if (isRange) {
      setValue(value.to);
    } else {
      setValue({ from: config.min, to: value ?? config.max });
    }
  };

  const handleChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      const [from, to] = value;
      setValue({ from, to });
    } else {
      setValue(value);
    }
  };

  useDebounce(
    () => {
      if (value) {
        onChange(value);
      }
    },
    1000,
    [value],
  );

  useEffect(() => {
    if (!arePricesEqual(price, value)) {
      setValue(price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  return (
    <div className={S.root}>
      <div className={S.content}>
        <Slider
          className={S.slider}
          value={isRange ? [value.from, value.to] : value ?? config.max}
          min={config.min}
          max={config.max}
          step={config.step}
          isRange={isRange}
          onChange={handleChange}
        />
        {isRange && (
          <RangeInputs
            from={value.from}
            to={value.to}
            min={config.min}
            max={config.max}
            step={config.step}
            onChange={handleChange}
          />
        )}
      </div>
      <Button className={S.button} fill="solid" size="medium" onClick={handleToggleButtonClick}>
        {isRange ? 'Simplify' : 'Specify'}
      </Button>
    </div>
  );
};
