import cx from 'classnames';
import { useAccount, useBalance } from 'wagmi';

import { EthIcon } from 'shared/components';
import { truncate } from 'shared/helpers';

import S from './ProfileInfo.module.scss';

type Props = {
  username?: string;
};

export const ProfileInfo: React.FC<Props> = ({ username }) => {
  const { address } = useAccount();
  const { data: balance } = useBalance({
    address,
    watch: true,
  });

  return (
    <div className={S.root}>
      <div>
        {username && <h3 className={S.title}>{username}</h3>}
        {address && (
          <div className={S.section}>
            <span className={S.address}>{truncate(address, 14, 4)}</span>
            <i className={cx('icon icon-coins', S.icon)} />
          </div>
        )}
      </div>
      {balance && (
        <div>
          <p className={S.label}>Balance</p>
          <div className={S.section}>
            <h3 className={S.balance}>{Number(balance.formatted).toFixed(3)} ETH</h3>
            <EthIcon className={S.icon} theme="light" />
          </div>
        </div>
      )}
    </div>
  );
};
