import cx from 'classnames';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

import { Checkbox, FileUploader, Input, Select, TextArea } from 'shared/components';
import { Option } from 'shared/types';

import S from './Field.module.scss';
import { FieldProps } from '../types';

type Props<T extends FieldValues> = {
  field: FieldProps<T>;
  value: ControllerRenderProps['value'];
  invalid: boolean;
  className?: string;
  options?: Option[];
  onChange: ControllerRenderProps['onChange'];
};

const Field = <T extends FieldValues>({ field, value, invalid, className, options = [], onChange }: Props<T>) => {
  const { icon, label, type, placeholder, validationOptions, fileProps } = field;

  if (type === 'file') {
    return (
      <FileUploader
        className={className}
        value={value}
        invalid={invalid}
        accept={validationOptions?.fileType && `${validationOptions.fileType}/*`}
        validationOptions={validationOptions}
        fileProps={fileProps}
        buttonText={label}
        onChange={onChange}
      />
    );
  }

  if (type === 'dropdown') {
    return (
      <Select
        className={className}
        options={options}
        value={value}
        invalid={invalid}
        placeholder={placeholder}
        onChange={onChange}
      />
    );
  }

  if (type === 'checkbox') {
    return <Checkbox className={cx(S.checkbox, className)} value={value} invalid={invalid} onChange={onChange} />;
  }

  if (type === 'textarea') {
    return (
      <TextArea
        className={className}
        value={value}
        invalid={invalid}
        placeholder={placeholder}
        maxLength={validationOptions?.maxLength}
        onChange={onChange}
      />
    );
  }

  return (
    <Input
      className={cx(S.input, className)}
      value={value}
      invalid={invalid}
      placeholder={placeholder}
      type={type}
      icon={icon}
      label={label}
      maxLength={validationOptions?.maxLength}
      onChange={onChange}
    />
  );
};

export { Field };
