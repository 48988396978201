import cx from 'classnames';
import { memo } from 'react';

import S from './Preloader.module.scss';

type Props = {
  className?: string;
};

const Component: React.FC<Props> = ({ className }) => <div className={cx(S.root, className)} />;

export const Preloader = memo(Component);
Preloader.displayName = 'Preloader';
