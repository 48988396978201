import * as R from 'ramda';

import { awaitTimeout } from 'shared/helpers';

import { getMockFeaturedArtist } from '../../fake';

export async function loadFeaturedArtists() {
  await awaitTimeout();
  return R.range(1, 13).map((id) => getMockFeaturedArtist(String(id)));
}
