import { Network, Alchemy } from 'alchemy-sdk';
import { configureChains, createClient, sepolia } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';

import { config } from 'shared/helpers';

const alchemyApiUrl = config.alchemyApiUrl as string;

const { chains, provider } = configureChains(
  [sepolia],
  [jsonRpcProvider({ rpc: () => ({ http: alchemyApiUrl }) }), publicProvider()],
);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
});

export const alchemy = new Alchemy({
  apiKey: config.alchemyApiKey,
  network: Network.ETH_SEPOLIA,
});
