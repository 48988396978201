import { awaitTimeout } from 'shared/helpers';
import type { StatisticsData } from 'shared/types';

export async function load(): Promise<StatisticsData> {
  await awaitTimeout();
  return {
    collectibles: '11,593',
    auctions: '1,200',
    artists: '5,983',
  };
}
