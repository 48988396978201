import { observer } from 'mobx-react-lite';
import { useMount } from 'react-use';

import { useStores } from 'core';
import { EditProfileForm } from 'features/profile';

const EditProfile = observer(() => {
  const { profileStore } = useStores();
  const { loadProfile } = profileStore;

  useMount(() => {
    loadProfile();
  });

  return <EditProfileForm />;
});

export { EditProfile };
